<template>
	<section class="footer">
		<div class="price-box">
			<div v-if="viajeResumenDto.precioDesde > 0">
				<p class="price-from">{{ $t('general.desde') }}</p>
				<p class="price">
					{{ setNumberToNaturalRounded(viajeResumenDto.precioDesde) }}{{agenciaStore.getCurrency}} <span class="price-text">{{ perPerson }}</span>
				</p>
			</div>
		</div>
		<div class="button-box">
			<MyGocuButton class="button" type="secondary" :label="$t('general.reservar')" @click="goToAlojamientos"/>
		</div>
	</section>
</template>

<script setup>
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useRouter, useRoute } from 'vue-router';
	import { setNumberToNaturalRounded } from '@/helpers/numberUtils';
	import {
		GruposReservaAlojamientos,
		GruposReservaFormularioUnirseSinAlojamiento
	} from '@/router/RouterNames';
	import MyGocuButton from '@/components/common/buttons/MyGocuButton';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { appGlobalStore } from '@/store_pinia/app';

	const { t } = useI18n();
	const route = useRoute();
	const router = useRouter();
	const agenciaStore = agenciaStoreModule();
	const storeGlobal = appGlobalStore();

	const props = defineProps({
		viajeResumenDto: {
			type: Object,
			required: true,
		},
	});


	const perPerson = computed(() => {
		return `/${t('general.porPersona').match(/pers/)[0]}`;
	});

	function goToAlojamientos() {
		try {
			storeGlobal.loadingGlobal = true;
			if (props.viajeResumenDto.hasAlojamiento) {
				router.push({
					name: GruposReservaAlojamientos,
					query: { grupo: route.query.grupo },
					params: {
						viajeId: props.viajeResumenDto.id,
					},
				});
			} else {
				router.push({
					name: GruposReservaFormularioUnirseSinAlojamiento,
					query: { grupo: route.query.grupo },
					params: { viajeId: props.viajeResumenDto.id }
				});
			}
		} finally {
			storeGlobal.loadingGlobal = false;
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/san.scss';

	.trip-card {
		.footer {
			@extend .card-footer, .d-flex, .justify-content-between, .align-items-end, .rounded-bottom-4, .border-0, .pb-3;
			background: white;

			@include media-breakpoint-up(md) {
				height: 52px;
			}

			.price-box {
				@extend .position-relative;
				color: var(--bs-primary);
				.price-from {
					@extend .position-absolute, .m-0;
					bottom: 1.5rem;
					font-size: 0.625rem;
				}
				.price {
					@extend .card-text, .fw-semibold;
					font-size: 1.25rem;
					.price-text {
						@extend .fst-normal;
						font-size: 0.5rem;
					}
				}
			}
			.button-box {
				.button {
					@extend .btn, .btn-primary, .border-0, .rounded-4;
					box-shadow: 0px 1px 2px rgba(33, 36, 68, 0.35);
					color: var(--bs-secondary);
					font-size: 0.875rem;
					font-weight: 500;
					height: 37px;
					width: 103px;
				}
			}
		}
	}
</style>
