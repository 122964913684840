import { ESTADOS_RESERVA } from '@/constants/reservaConstants';

export function getPaqueteBasicoForServicio(agrupacionesServicios, servicio) {
	const agrupacion = agrupacionesServicios.find(ags => ags.id === servicio.agrupacion.id);
	if (agrupacion.paqueteBasico) {
		return agrupacion.servicios.find(s => s.paqueteBasico);
	}
	return null;
}

export function isServicioContratable(reserva, servicio) {
	return !reserva.persisted || reserva.estado === ESTADOS_RESERVA.BLOQUEADA || !servicio.contratableSoloCreacion;
}

export function isServicioDescontratable(reserva, servicio) {
	return servicio == null || !reserva.persisted || reserva.estado === ESTADOS_RESERVA.BLOQUEADA || servicio.borrable || servicio.justSelected;
}

export const getCategoriasServiciosMetaData = () => {
	return [
		{
			selector: 'forfait',
			icono: 'fa-solid fa-credit-card fa-2x',
		},
		{
			selector: 'restauracion',
			icono: 'fa-solid fa-bowl-food fa-2x',
		},
		{
			selector: 'alquiler',
			icono: 'fa-solid fa-person-skiing-nordic fa-2x',
		},
		{
			selector: 'clases',
			icono: 'fa-solid fa-person-skiing fa-2x',
		},
		{
			selector: 'transporte',
			icono: 'fa-solid fa-van-shuttle fa-2x',
		},
		{
			selector: 'actividad',
			icono: 'fa-solid fa-plus',
		},
		{
			selector: 'seguro',
			icono: 'fa-solid fa-notes-medical fa-2x',
		},
		{
			selector: 'parking',
			icono: 'fa-solid fa-car fa-2x',
		},
		{
			selector: 'greenFee',
			icono: 'fa-solid fa-golf-ball-tee fa-2x',
		},
		{
			selector: 'comfort',
			icono: 'fa-solid fa-face-smile-beam fa-2x',
		},
		{
			selector: 'festival',
			icono: 'fa-solid fa-music fa-2x',
		},
		{
			selector: 'personal',
			icono: 'fa-solid fa-user-group fa-2x',
		},
		{
			selector: 'mascota',
			icono: 'fa-solid fa-dog fa-2x',
		},
		{
			selector: 'competicion',
			icono: 'fa-solid fa-trophy fa-2x',
		},
	];
};
