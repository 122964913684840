<template>
	<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.25661 9.91048L11.1866 16.7493C11.817 17.3714 11.817 18.38 11.1866 19.0022C10.5561 19.6243 9.53403 19.6243 8.90362 19.0022L0.832189 11.0369C0.201771 10.4148 0.201771 9.40615 0.832189 8.78403L8.90362 0.818803C9.53404 0.196679 10.5561 0.196679 11.1866 0.818803C11.817 1.44092 11.817 2.44959 11.1866 3.07171L4.25661 9.91048Z"
			fill="white" />
		<mask
			id="mask0_2060_17740"
			style="mask-type: luminance"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="12"
			height="20">
			<path
				d="M4.25661 9.91048L11.1866 16.7493C11.817 17.3714 11.817 18.38 11.1866 19.0022C10.5561 19.6243 9.53403 19.6243 8.90362 19.0022L0.832189 11.0369C0.201771 10.4148 0.201771 9.40615 0.832189 8.78403L8.90362 0.818803C9.53404 0.196679 10.5561 0.196679 11.1866 0.818803C11.817 1.44092 11.817 2.44959 11.1866 3.07171L4.25661 9.91048Z"
				fill="white" />
		</mask>
		<g mask="url(#mask0_2060_17740)"></g>
	</svg>
</template>
