<template>
	<div class="container">
		<div class="row">
			<template v-for="tipoViaje in tiposViajesGrupos" :key="tipoViaje">
				<div class="col-lg-3 col-6">
					<router-link
						:to="{
							name: ViajesGrupoCatalogo,
							query: { grupo: tipoViaje.query },
						}"
						class="router">
						<div class="card-trip">
							<img :src="tipoViaje.imagenUrl" class="background" />
							<div class="gradient-background" />
							<div class="card-text">
								<span>{{ tipoViaje.titulo }}</span>
								<svg
									width="56"
									height="55"
									viewBox="0 0 56 55"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									class="svg-shadow">
									<g filter="url(#filter0_d_193_210)">
										<path
											d="M26.7476 51.8V0.599976H29.3076V51.8H26.7476ZM1.7876 27.48V25.048H54.2676V27.48H1.7876Z"
											fill="white" />
									</g>
								</svg>
							</div>
						</div>
					</router-link>
				</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref, onMounted, onUnmounted } from 'vue';

	const isMobile = ref(window.innerWidth <= 992);

	const handleResize = () => {
		isMobile.value = window.innerWidth <= 992;
	};

	const tiposViajesGrupos = computed(() => [
		{
			query: 'festivales',
			titulo: 'FESTIVALES',
			imagenUrl: require('@/assets/fotos/FestivalesSAN.webp'),
		},
		{
			query: 'barcos',
			titulo: 'BARCOS',
			imagenUrl: require('@/assets/fotos/BarcosSAN.webp'),
		},
		{
			query: 'surf',
			titulo: 'SURF',
			imagenUrl: require('@/assets/fotos/SurfSAN.webp'),
		},
		{
			query: 'playa',
			titulo: 'PLAYA',
			imagenUrl: require('@/assets/fotos/PlayaSAN.webp'),
		},
	]);

	onMounted(() => {
		window.addEventListener('resize', handleResize);
	});

	onUnmounted(() => {
		window.removeEventListener('resize', handleResize);
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.container {
		max-width: 90vw;
	}

	.row {
		@extend .d-flex, .justify-content-center;
		max-width: 90vw;
		margin: 0 auto;
	}

	[class*='col-'] {
		min-width: 19.47vw;
	}

	[class*='col-6'] {
		margin-bottom: 5vw;
	}

	.router {
		@extend .d-flex, .justify-content-center;
	}

	.card-trip {
		@extend .position-relative, .overflow-hidden, .text-white;
		cursor: pointer;
		width: 19.47vw;
		height: 16.76vw;
		border-radius: var(--bs-border-radius-xl);
		z-index: 1;
		transition: transform 0.3s ease, z-index 0s ease 0.3s;

		&:hover {
			transform: scale(1.1);
		}

		img.background {
			@extend .img-fluid, .w-100, .h-100, .object-fit-cover, .position-absolute, .top-0, .start-0;
			z-index: 1;
			border-radius: var(--bs-border-radius-xl);
			transition: transform 0.3s ease;
		}

		&:hover img {
			transform: scale(1.2);
		}

		&:hover .gradient-background {
			opacity: 0;
		}

		@include media-breakpoint-down(lg) {
			width: 40vw;
			height: 40vw;
			&:after {
				background: linear-gradient(
					180deg,
					rgba(0, 0, 0, 0.986836) -23.38%,
					rgba(41, 40, 72, 0.99) 29.55%,
					rgba(37, 36, 64, 0.99) 48.07%,
					rgba(26, 28, 53, 0.99) 74.9%
				);
				opacity: 0.5;
				z-index: 2;
			}
		}

		.card-text span {
			transition: opacity 0.3s ease;
			font-size: 30px;
			@include media-breakpoint-down(lg) {
				font-size: 20px;
			}
		}

		&:hover .card-text span {
			@extend .opacity-0;
		}

		&:hover .card-text svg {
			@extend .opacity-100;
		}

		.card-text svg {
			@extend .opacity-0, .position-absolute, .top-50, .start-50;
			transition: opacity 0.5s ease;
			transform: translate(-50%, -50%);
		}

		&:hover .card-text svg {
			@extend .d-block;
		}
	}

	.gradient-background {
		@extend .position-absolute, .top-0, .w-100, .h-100, .start-0;
		background: linear-gradient(0deg, rgba(25, 27, 52, 0.199), rgba(25, 27, 52, 0.199));
		z-index: 2;
		transition: opacity 0.3s ease;
	}

	.card-trip:hover .gradient-background {
		opacity: 0;
	}

	.svg-shadow {
		filter: drop-shadow(0px 2px 1px var(--bs-primary));
	}

	.card-text {
		@extend .fs-4,
			.text-white,
			.position-absolute,
			.d-flex,
			.justify-content-center,
			.align-items-center,
			.w-100,
			.h-100,
			.start-0;
		font-weight: 600;
		text-shadow: 0 2px 1px rgba(var(--bs-primary-rgb), 0.5);
		z-index: 3;
	}
</style>
