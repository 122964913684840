const DATA_PAGES = {
	skiandnight: {
		title: 'home.banner.title',
		subTitle: 'home.banner.subtitle',
		titleAgrupaciones: 'home.festivales.title',
		subTitleAgrupaciones: 'home.festivales.subtitle',
		alternative: 'home.banner.experienciasAnyo',
		textBtn: 'home.banner.experienciasAMedida',
		textBtnExperiencias: 'alojamiento.todasExperiencias',
		textBtnCatalogo: 'home.banner.experienciasAnyo',
	},
	sbspain: {
		title: 'home.banner.titleBsspain',
		titleAgrupaciones: 'home.festivales.titleBsspain',
		subTitleAgrupaciones: 'home.festivales.subtitleBsspain',
		domain: 'sbspain',
		textBtn: null,
		textBtnExperiencias: 'alojamiento.todasExperiencias',
		textBtnCatalogo: 'home.banner.eligeTuEvento',
	},
	['poker-red']: {
		title: 'home.banner.titlePr',
		titleAgrupaciones: 'home.festivales.titlePr',
		subTitleAgrupaciones: 'home.festivales.subtitlePr',
		domain: 'poker-red',
		textBtn: 'home.buscadorSection.button',
		textBtnExperiencias: 'general.reservar',
		textBtnCatalogo: 'home.banner.experienciasAnyo',
		urlRedirect: 'poker-red.travel/agrupaciones',
	},
	default: {
		title: '',
		titleAgrupaciones: 'home.festivales.titleDefault',
		subTitleAgrupaciones: 'home.festivales.subtitleDefault',
		domain: 'default',
		textBtn: null,
		textBtnExperiencias: 'home.banner.experiencias',
		textBtnCatalogo: 'home.banner.experiencias',
	},
};

export const getPageData = () => {
	const domain = ['sbspain', 'poker-red', 'skiandnight'].find(domain => checkDomain(domain)) || 'default';
	return DATA_PAGES[domain];
};

export function checkDomain(domains) {
	const domainsArray = Array.isArray(domains) ? domains : [domains];
	return domainsArray.some(domain => window.location.host.includes(domain));
}
