import { defineStore } from 'pinia';
import moment from 'moment';
import i18n from '@/i18n';

const { t } = i18n.global;

export const useFiltroGruposStore = defineStore('filtroGrupos', {
	state: () => ({
		filtros: {
			lugaresExperiencia: [],
			precios: [],
			agrupaciones: [],
			tiposViaje: [],
			month: null,
		},
		viajesFiltrados: [],
	}),
	getters: {
		getLugaresExperiencia(state) {
			return state.filtros.lugaresExperiencia;
		},
		getAgrupaciones(state) {
			return state.filtros.agrupaciones;
		},
		getTiposViaje(state) {
			return state.filtros.tiposViaje;
		},
		getMonth(state) {
			return state.filtros.month;
		},
		getViajesFiltrados(state) {
			return state.viajesFiltrados
		}
	},
	actions: {
		resetFiltros() {
			this.$reset();
		},
		setFiltroLugaresExperiencia(listaLugaresExperiencia) {
			this.filtros.lugaresExperiencia = listaLugaresExperiencia;
		},
		setFiltroPrecios(listaPrecio) {
			this.filtros.precios = listaPrecio;
		},
		setFiltroAgrupaciones(listaAgrupaciones) {
			this.filtros.agrupaciones = listaAgrupaciones;
		},
		setFiltroTipoViaje(tiposViaje) {
			this.filtros.tiposViaje = tiposViaje;
		},
		setFiltroMonth(month) {
			this.filtros.month = month;
		},
		aplicarFiltros(allViajes) {
			let viajesFiltradosTmp = allViajes;

			if (this.filtros.lugaresExperiencia.length > 0) {
				viajesFiltradosTmp = viajesFiltradosTmp.filter(viaje => {
					return this.filtros.lugaresExperiencia.some(lugarExpeciencia => {
						return viaje.lugarExperiencia.id === lugarExpeciencia.id;
					});
				});
			}
			if (this.filtros.precios.length > 0) {
				const [minPrecio, maxPrecio] = this.filtros.precios;
				viajesFiltradosTmp = viajesFiltradosTmp.filter(viaje => {
					return viaje.precioDesde >= minPrecio && viaje.precioDesde <= maxPrecio;
				});
			}
			if (this.filtros.agrupaciones.length > 0) {
				viajesFiltradosTmp = viajesFiltradosTmp.filter(viaje => {
					return this.filtros.agrupaciones.some(agrupacion => {
						return viaje.agrupaciones && viaje.agrupaciones.some(each => each.id === agrupacion.id);
					});
				});
			}
			if (this.filtros.tiposViaje.length > 0) {
				viajesFiltradosTmp = viajesFiltradosTmp.filter(viaje => {
					return this.filtros.tiposViaje.some(tipoViaje => {
						return viaje.tipos && viaje.tipos.some(each => each.id === tipoViaje.id);
					});
				});
			}
			if (this.filtros.month !== null) {
				viajesFiltradosTmp = viajesFiltradosTmp.filter(viaje => {
					const selectedYear = this.filtros.month.year();
					const selectedMonthNumber = this.filtros.month.month();

					const tripStartDate = moment(viaje.fechaInicio);
					const tripEndDate = moment(viaje.fechaFin);

					return (
						(tripStartDate.year() === selectedYear && tripStartDate.month() === selectedMonthNumber) ||
						(tripEndDate.year() === selectedYear && tripEndDate.month() === selectedMonthNumber) ||
						(tripStartDate <= this.filtros.month && tripEndDate >= this.filtros.month)
					);
				});
			}

			this.viajesFiltrados = ordernarViajes(allViajes, viajesFiltradosTmp, this.filtros.tiposViaje);
		},
	},
});

function ordernarViajes(allViajes, viajesFiltrados, tiposSeleccionados) {
	let viajes = {};
	const viajesSinTipos = allViajes.some(viaje => viaje.tipos.length === 0);
	if (viajesSinTipos && tiposSeleccionados.length === 0) {
		viajes[t('home.experiencias.all')] = viajesFiltrados;
	}
	if (viajesFiltrados) {
		viajesFiltrados.map(viaje => {
			viaje.tipos
				.filter(tipo => tiposSeleccionados.length === 0 || tiposSeleccionados.some(each => each.id === tipo.id))
				.forEach(tipo => {
					if (!viajes[tipo.descripcion]) {
						viajes[tipo.descripcion] = [];
					}
					viajes[tipo.descripcion].push(viaje);
			});
		});
		return viajes;
	}
}
