import { defineStore } from 'pinia';
import ViajesGrupoApiService from '../services/api/skiandnight/ViajesGrupoApiService';

export const buscadorGrupos = defineStore('buscadorGrupos', {
	state: () => ({
		tipoViaje: null,
		lugarExperiencia: null,
		date: null,
		filtrosViajes: {
			destino: null,
			tiposGrupo: null,
		},
	}),

	actions: {
		async setFilterInformation() {
			if (
				(this.filtrosViajes.destino && this.filtrosViajes.destino.length > 0 ||
				this.filtrosViajes.tiposGrupo && this.filtrosViajes.tiposGrupo.length > 0)
			) {
				return;
			}
			this.filtrosViajes = await ViajesGrupoApiService.getFiltroViajes();
		},

		setTipoViajeBuscadorGrupos(group) {
			this.tipoViaje = group;
		},

		setLugarExperiencia(lugarExperiencia) {
			this.lugarExperiencia = lugarExperiencia;
		},

		setDateBuscadorGrupos(date) {
			this.date = date;
		},

		clearTipoViajeBuscadorGrupos() {
			this.tipoViaje = null;
		},

		clearDestinyBuscadorGrupos() {
			this.lugarExperiencia = null;
		},

		clearDateBuscadorGrupos() {
			this.date = null;
		},

		clearBuscadorGrupos() {
			this.$reset();
		},
	},
});
