<template>
	<template v-if="!loading">
		<div v-if="$route.name == 'Landing'">
			<router-view :key="$route.fullPath" />
		</div>

		<div v-else class="container-s">
			<TheHeader />
			<div class="content">
				<div class="wrapper">
					<LoadingAnimation v-if="storeGlobal.loadingGlobal" />
					<router-view :key="$route.fullPath" />
				</div>
			</div>
			<TheFooter />
		</div>
		<cookie-manager />
	</template>
</template>

<script setup>
	import TheHeader from '@/components/TheHeader';
	import TheFooter from '@/components/TheFooter';
	import CookieManager from '@/components/shared/CookieManager.vue';
	import LoadingAnimation from './components/shared/LoadingAnimation.vue';
	import { onBeforeMount, ref } from 'vue';
	import { useStore } from 'vuex';
	import { appGlobalStore } from '@/store_pinia/app';
	import { addScriptsHeadTags } from 'src/services/utils/ScriptsUtils';
	import HomeApiService from './services/api/skiandnight/HomeApiService';
	import axios from 'axios';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	const store = useStore();
	const storeGlobal = appGlobalStore();
	const agenciaStore = agenciaStoreModule();
	// This is needed because storeGlobal.loadingGlobal can not be used for showing the hole component
	// due to is changed in lots of inner components
	const loading = ref(true);

	let file = document.createElement('link');
	let headTitle = document.querySelector('head');
	let setFavicon = document.createElement('link');

	async function init() {
		try {
			storeGlobal.loadingGlobal = true;

			store.dispatch('login/obtieneInformacionUsuario');
			// si esta la seteo al vuex como login = true

			const res = await HomeApiService.getAgencia();
			agenciaStore.setData(res);
			document.title = res.recursos.meta_titulo;
			var meta = document.createElement('meta');
			meta.name = 'description';
			meta.content = res.recursos.meta_descripcion;
			document.getElementsByTagName('head')[0].appendChild(meta);
			file.rel = 'stylesheet';
			file.href = res.recursos.css;
			document.head.appendChild(file);

			storeGlobal.setValoresResources(res.recursos);
			selectorIdiomas(res.recursos);
			setFavicon.setAttribute('rel', 'shortcut icon');
			setFavicon.setAttribute('href', res.recursos.favicon);
			headTitle.appendChild(setFavicon);

			const permisos = await axios.get(res.recursos.permisos);
			storeGlobal.setPermisos(permisos.data);

			addScriptsHeadTags(res.recursos.gtm);
		} catch (err) {
			console.error(err);
		} finally {
			storeGlobal.loadingGlobal = false;
			loading.value = false;
		}
	}

	function selectorIdiomas(response) {
		axios.get(response.translations_en).then(res => {
			storeGlobal.setTranslations(res.data, 'en');
		});
		axios.get(response.translations_es).then(res => {
			storeGlobal.setTranslations(res.data, 'es');
		});
		axios.get(response.translations_fr).then(res => {
			storeGlobal.setTranslations(res.data, 'fr');
		});
		axios.get(response.translations_it).then(res => {
			storeGlobal.setTranslations(res.data, 'it');
		});
	}

	onBeforeMount(async () => await init());
</script>

<style lang="scss">
	@import '~@/assets/scss/main.scss';
	.container-s {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}

	.content {
		flex: 1;
	}
</style>
