<template>
	<template v-if="agrupacion">
		<div class="pb-4">
			<AgrupacionesExperienciasHeader :uri="route.params.uri" :agrupacion="agrupacion"></AgrupacionesExperienciasHeader>
			<div class="px-3 my-5 container justify-center">
				<div class="title-desktop text-primary fw-bold text-center mb-2">{{ agrupacion.titulo }}</div>
				<p
					id="descripcion"
					v-sanitize-html="formattedFestivalDescription"
					class="descripcion-festival text-center translate"></p>
			</div>
			<div>
			</div>
			<div v-if="agrupacion.viajes && agrupacion.viajes.length > 0" class="px-3 mt-5 container text-primary">
				<div class="d-flex justify-content-between align-items-center">
					<p class="fs-3 titulo-lineup m-0">{{ $t('alojamiento.nuestrasExperiencias') }}</p>
					<button class="btn btn-secondary" @click="goTodasExperiencias">
						{{ $t('alojamiento.verTodasExperiencias') }}
					</button>
				</div>
				<CarouselViajesCatalogo :lista-viajes="agrupacion.viajes" class="viaje-area__viajes" />
			</div>
			<div v-if="agrupacion.video" class="container px-3 my-3">
				<div class="row gx-0">
					<div class="col-12">
						<div class="me-0 mb-5">
							<iframe width="100%" :height="movil ? 250 : 500" :src="agrupacion.video" allowfullscreen></iframe>
						</div>
					</div>
				</div>
			</div>
			<div v-if="agrupacion.imagenesCarrusel.length > 0" class="viaje-area__viajes container px-3 text-primary">
				<div class="d-flex justify-content-between align-items-center">
					<p class="fs-3 titulo-lineup mb-4">{{ $t('alojamiento.imagenes') }}</p>
				</div>
				<CarouselAgrupacionesExperiencias :lista-fotos="agrupacion.imagenesCarrusel" />
			</div>
			<div v-if="agrupacion.itinerarios && agrupacion.itinerarios.length > 0" class="container px-3 my-5">
				<div class="text-left">
					<p class="fs-3 titulo-lineup text-primary mb-4">{{ $t('titulos.itinerario') }}</p>
					<ItinerarioCarousel :itinerarios="agrupacion.itinerarios" :localizacion="agrupacion.ubicacion" />
				</div>
			</div>
			<div v-if="agrupacion.artistas && agrupacion.artistas.length > 0" class="container px-2 lineup-container">
				<base-carousel
					:items-carousel="agrupacion.artistas"
					:is-loading="false"
					:autoplay-time="5000"
					:max-elements="4"
					class="mt-5 container"
					style="max-width: 1440px">
					<template #title>
						<div class="d-flex justify-content-between align-items-center">
							<p class="fs-3 titulo-lineup m-0 p-0">{{ $t('alojamiento.lineUp') }}</p>
						</div>
					</template>
					<template #itemCarousel="itemCarouselProps">
						<div
							class="d-flex flex-column gap-3"
							:class="{ 'full-height-container': !itemCarouselProps.data.urlCancion }">
							<img
								class="img-carousel-lineup"
								:class="{ 'img-full-height': !itemCarouselProps.data.urlCancion }"
								:src="itemCarouselProps.data.imagenArtista"
								alt="" />
							<iframe
								v-if="itemCarouselProps.data.urlCancion"
								style="border-radius: var(--bs-border-radius-xl); padding: 0 15px"
								:src="itemCarouselProps.data.urlCancion"
								width="100%"
								height="102"
								frameBorder="0"
								allowfullscreen=""
								allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
								loading="lazy" />
						</div>
					</template>
				</base-carousel>
			</div>
		</div>
	</template>

	<template v-else>
		<Spinner />
	</template>
</template>
<script setup>
	import { ref, computed, onMounted, onUnmounted } from 'vue';
	import { useAgrupacionesStore } from '@/store_pinia/agrupaciones';
	import { useRoute, useRouter } from 'vue-router';
	import BaseCarousel from '@/components/Carousel/BaseCarousel.vue';
	import CarouselViajesCatalogo from '@/components/Carousel/CarouselViajesCatalogo.vue';
	import { appGlobalStore } from '@/store_pinia/app';
	import Spinner from '@/components/SpinnerLoading.vue';
	import CarouselAgrupacionesExperiencias from '@/components/Carousel/CarouselAgrupacionesExperiencias.vue';
	import AgrupacionesExperienciasHeader from '@/views/productos/agrupaciones/AgrupacionesExperienciasHeader.vue';
	import ItinerarioCarousel from '@/components/Carousel/ItinerarioCarousel.vue';
	import { ViajesGrupoCatalogo } from '@/router/RouterNames';
	import { useFiltroGruposStore } from '@/store_pinia/filtros/filtroGrupos';

	const agrupacion = ref();
	const agrupacionesStore = useAgrupacionesStore();
	const filtroGruposStore = useFiltroGruposStore();
	const route = useRoute();

	const router = useRouter();
	const isMobile = ref(window.innerWidth <= 1033);

	const storeGlobal = appGlobalStore();
	const codigoAgrupacion = route.query.codigo || null;

	const movil = computed(() => window.screen.availWidth <= 768);

	const formattedFestivalDescription = computed(() => {
		return agrupacion.value.descripcion.replace(/\r\n/g, '<br>');
	});

	function handleResize() {
		isMobile.value = window.innerWidth <= 1033;
	}

	function goTodasExperiencias() {
		filtroGruposStore.resetFiltros();
		filtroGruposStore.setFiltroAgrupaciones([agrupacion.value]);
		router.push({
			name: ViajesGrupoCatalogo,
		});
	}

	onMounted(async () => {
		storeGlobal.loadingGlobal = true;
		try {
			agrupacion.value = await agrupacionesStore.fetchAgrupacion(route.params.uri, codigoAgrupacion);

			if (agrupacion.value.tituloSeo && agrupacion.value.descripcionSeo) {
				document.title = agrupacion.value.tituloSeo;
				document.querySelector("[name='description']").remove();
				const meta = document.createElement('meta');
				meta.name = 'description';
				meta.content = agrupacion.value.descripcionSeo;
				document.getElementsByTagName('head')[0].appendChild(meta);
			}
		} catch (error) {
			console.error('Error al cargar agrupacion:', error);
		} finally {
			storeGlobal.loadingGlobal = false;
			window.addEventListener('resize', handleResize);
		}
	});

	onUnmounted(() => {
		window.removeEventListener('resize', handleResize);
	});
</script>

<style lang="scss" scoped>
	.btn-secondary {
		font-size: 0.9rem;
		border-radius: var(--bs-border-radius-xl);
	}

	.festivales-detalle {
		background-color: white;
		min-height: 40vmax;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
	}

	@media (max-width: 768px) {
		.festivales-detalle {
			min-height: 75vmax;
		}
	}

	.festival-img-fondo {
		min-height: 90vh;
		background-size: cover;
		background-position: top;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.imagenes-secundarias-festival {
		margin-top: -11vmax;
	}

	.titulo-descripcion {
		font-weight: 700;
		font-size: 1.2rem;
		opacity: 0.7;
	}

	.informacion {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6;
		line-clamp: 6;
		-webkit-box-orient: vertical;
	}

	.descripcion-festival {
		font-size: 0.95rem;
	}

	.ubicacion-festival {
		font-size: 1.3rem;
		margin: 0px;
		font-weight: 700;
		opacity: 0.7;
	}

	.titulo {
		font-weight: 800;
	}

	.subtitulo {
		font-weight: 800;
	}

	.img-carousel {
		border-radius: var(--bs-border-radius-xl);
		width: 280px;
		height: 180px;
		background-size: cover;
		padding-right: 100px;
		background-position: center;
	}

	.titulo-lineup {
		font-weight: 700;
	}

	.fecha-festival {
		font-weight: 800;
	}

	.titulo-sponsors {
		font-weight: 700;
	}

	.img-carousel-lineup {
		width: 100%;
		padding: 15px 15px 0 15px;
		object-fit: cover;

		&.img-full-height {
			height: 100%;
		}
	}

	.ver-mapa {
		font-size: 0.9rem;
	}

	.viaje-area__viajes {
		:deep(.carousel__slide) {
			flex-shrink: 1;
			gap: 10px;
			margin-right: 10px;
			margin-left: 3px;
		}

		@media (max-width: 768px) {
			:deep(.carousel__slide) {
				flex-shrink: 0;
				margin: 0;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: flex-start;
			}
		}
	}

	@media screen and (max-width: 992px) {
		.text-container {
			display: flex;
			flex-direction: column;

			.left,
			.right {
				width: 100%;
			}
		}
	}

	@media (max-width: 768px) {
		.img-carousel {
			width: 180px;
			height: 130px;
			margin-top: 60px;
		}
		.festival-img-fondo {
			min-height: 65vh;
		}
	}

	.full-height-container {
		height: 100%;
	}

	.lineup-container {
		:deep(.carousel__prev),
		:deep(.carousel__next) {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background: transparent;
			border: none;
			color: var(--bs-primary);
			font-size: 2rem;
			z-index: 2;
		}

		:deep(.carousel__prev) {
			left: 0;
		}

		:deep(.carousel__next) {
			right: 0;
		}

		:deep(.carousel__track) {
			position: relative;
		}
	}
</style>
