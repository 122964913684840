import { defineStore } from 'pinia';
import HomeApiService from '../services/api/skiandnight/HomeApiService';

export const apresSkisStore = defineStore('apresSkisStore', {
	state: () => ({
		apresSkis: null,
		apresSki: null,
	}),
	actions: {
		async fetchApresSkis() {
			if (this.apresSkis === null) {
				this.apresSkis = await HomeApiService.getApresSkis();
			}
			return this.apresSkis;
		},
		async fetchApresSki(apresSkiId) {
			if (this.apresSki === null || this.apresSki.id !== apresSkiId) {
				if (this.apresSkis !== null) {
					this.apresSki = this.apresSkis.find(apresSki => apresSki.id === apresSkiId);
				} else {
					this.apresSki = await HomeApiService.getApresSkiById(apresSkiId);
				}
			}
			return this.apresSki;
		},
	},
});
