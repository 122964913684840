<template>
	<div v-if="!loading">
		<div
			v-if="validateImageFromBack(dataBlog.imagen)"
			class="blog_banner"
			:style="{ backgroundImage: 'url(/' + dataBlog.imagen + ')' }"/>
		<div v-else class="banner-img default-background"/>

		<div class="py-5 blog-text mx-auto" style="max-width: 1200px">
			<div v-if="dataBlog.lugaresExperiencia" class="tags d-flex flex-column flex-md-row px-4 px-lg-3">
				<div class="publicado row">
					<h6 class="text-primary">PUBLICADO EN</h6>
					<p class="text-muted">
						{{ formatDateDDmmmYYYY(dataBlog.fechaCreacion) }}
					</p>
				</div>
				<div class="row">
					<div class="categoria col-6">
						<h6 class="text-primary">ESTACIÓN</h6>
						<span class="text-muted">
							{{ dataBlog.lugaresExperiencia[0] }}
						</span>
					</div>
					<div class="categoria col-6">
						<h6 class="text-primary">CATEGORÍA</h6>
						<p class="text-muted">
							{{ dataBlog.categorias[0] }}
						</p>
					</div>
				</div>
			</div>
			<div class="px-3 px-lg-0">
				<div class="row justify-content-around mt-3">
					<!-- <div class="col-md-12 col-lg-8 px-sm-5 px-lg-0"> -->
					<div class="col-md-12 col-lg-8">
						<div class="pe-4">
							<h1 class="fw-bolder mb-3 text-primary" style="word-wrap: break-word; height: fit-content">
								{{ dataBlog.titulo }}
							</h1>
						</div>
						<div class="pe-4">
							<p v-sanitize-html="dataBlog.texto" class="mt-5 mt-md-0" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="social-media p-3">
			<div>
				<div class="d-flex">
					<div>
						<h6 class="text-primary fw-bolder">COMPARTIR</h6>
						<div class="mt-2">
							<a :href="linkedin" target="_blank">
								<i class="fa-brands fa-linkedin fa-2x mx-2" />
							</a>
							<a :href="twitter" target="_blank">
								<i class="fa-brands fa-twitter fa-2x mx-2" />
							</a>
							<a :href="facebook" target="_blank">
								<i class="fa-brands fa-facebook-f fa-2x mx-2" />
							</a>
							<a :href="whatsapp" target="_blank">
								<i class="fa-brands fa-whatsapp fa-2x mx-2" />
							</a>
						</div>
					</div>

					<div class="mx-5">
						<h6 class="text-primary fw-bolder">ETIQUETAS</h6>
						<p>
							<span v-for="etiqueta in dataBlog.etiquetas" :key="etiqueta">
								{{ etiqueta + ' ' }}
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="social-media px-3">
			<HomeCarousel />
		</div>

		<div class="text-center my-4">
			<button class="btn btn-primary" @click="$router.push({ name: 'BlogLanding' })">
				{{ $t('botones.volver').toLocaleUpperCase() }} AL BLOG
			</button>
		</div>
	</div>
	<div v-else class="container-loading">
		<SpinnerVue />
	</div>
</template>

<script>
	import HomeApiService from 'src/services/api/skiandnight/HomeApiService';
	import { useRoute } from 'vue-router';

	export default {
		beforeCreate: async function () {
			const currentRoute = await useRoute();
			const metaData = await HomeApiService.getBlogByUriSeo(currentRoute.params.blogUri);
			const jsonScript = document.createElement('script');
			jsonScript.setAttribute('type', 'application/ld+json');
			const baseUrl = process.env.VUE_APP_SKI_AND_NIGHT_BASE_URL;

			const data = {
				'@context': 'https://schema.org',
				'@type': 'BlogPosting',
				mainEntityPage: {
					'@type': 'WebPage',
					'@id': metaData.schema.link,
				},
				headline: metaData.schema.headline,
				description: metaData.schema.descripcion,
				image: baseUrl + metaData.schema.imagen,
				author: {
					'@type': 'Organization',
					name: metaData.schema.organizacion,
					url: '',
				},
				datePublished: metaData.schema.fechaPublicacion,
			};

			jsonScript.textContent = JSON.stringify(data);
			document.head.appendChild(jsonScript);

			if (metaData['titulo'] && metaData['descripcion']) {
				document.title = metaData.titulo;
				document.querySelector("[name='description']").remove();
				var meta = document.createElement('meta');
				meta.name = 'description';
				meta.content = metaData.descripcion;
				document.getElementsByTagName('head')[0].appendChild(meta);
			}
		},
	};
</script>

<script setup>
	import HomeCarousel from 'src/components/home/HomeCarousel.vue';
	import { ref, watch } from 'vue';
	import { formatDateDDmmmYYYY } from 'src/helpers/dateUtiles';
	import SpinnerVue from 'src/components/SpinnerLoading.vue';
	import { facebook, twitter, linkedin, whatsapp } from '@/constants/socialMedias.js';

	const route = useRoute();

	const loading = ref(true);

	const dataBlog = ref();

	const validateImageFromBack = urlImageBack => {
		return urlImageBack.startsWith('resources');
	};

	watch(route, () => {
		HomeApiService.getBlogByUri(route.params.blogUri)
			.then(res => {
				dataBlog.value = res;
			})
			.finally(() => {
				loading.value = false;
			});
	});

	HomeApiService.getBlogByUri(route.params.blogUri)
		.then(res => {
			dataBlog.value = res;
		})
		.finally(() => {
			loading.value = false;
		});

</script>

<style lang="scss" scoped>
	.default-background {
		background-image: url('src/assets/fotos/telesilla-banner.webp');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		margin: auto;
		min-height: 550px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&-container {
			max-width: 1440px;
			margin: 0 auto;
		}

		&-buttons {
			margin-bottom: 4rem;
		}
	}

	.container-loading {
		height: 80vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.blog_banner {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 0% 50%;
		margin: auto;
		min-height: 550px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&-container {
			max-width: 1440px;
			margin: 0 auto;
		}

		&-buttons {
			margin-bottom: 4rem;
		}
	}

	.btn-secondary {
		border-radius: var(--bs-border-radius-xl);
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
	}

	.social-media {
		max-width: 1200px;
		margin: auto;
	}
	.card-viajes {
		max-width: 1200px;
		margin: auto;
	}

	.card {
		border-radius: var(--bs-border-radius-xl);
		border: 0;
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
	}

	.blog-text {
		max-width: 1440px;
		margin: auto;
	}

	.carousel-content {
		max-width: 1440px;
		margin: auto;
	}

	.title {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		/* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.card {
		cursor: pointer;
		border-radius: var(--bs-border-radius-xl);
		position: relative;

		.icon {
			position: absolute;
			margin: auto;
			position: absolute;
			top: -20px;
			left: 0;
			right: 0;
			color: var(--bs-primary);
			transition: 0.3s;
		}

		.infor {
			transition: 0.3s;
		}

		&:hover {
			.infor,
			.icon {
				color: var(--bs-secondary);
			}
		}
	}

	.container-background {
		position: relative;

		.card-title {
			margin: auto;
			position: absolute;
			top: 80px;
			left: 0;
			bottom: 0;
			right: 0;
		}
	}

	.container-body {
		position: relative;

		.price {
			margin: auto;
			position: absolute;
			top: -20px;
			left: 0;
			right: 0;
			border-radius: var(--bs-border-radius-xl);
			box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
		}
	}

	.home-card {
		&__image {
			border-radius: 5%;
			mix-blend-mode: normal;
			opacity: 0.8;
			margin: auto;
		}

		&__title {
			font-size: 21px;
			font-weight: 700;
			line-height: 25.6px;
		}

		&__description {
			font-size: 14px;
			line-height: 17px;
			color: #6f6e8e;
		}
	}
	.titulo {
		font-size: 1.4rem;
		font-weight: 700 !important;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		padding-top: 1.5rem;
		padding-bottom: 0.5rem;
		@media only screen and (max-width: 990px) {
			padding-top: 0.7rem;
			margin-top: 1.5rem;
		}
	}
	.tarjeta-principal {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		border-radius: var(--bs-border-radius-xxl);
		background-color: #e7e7eb;
		position: relative;
		height: 290px;
		display: flex;
		flex-direction: column;
		position: relative;

		.icon {
			position: absolute;
			margin: auto;
			top: -20px;
			left: 0;
			right: 0;
			color: var(--bs-primary);
			transition: 0.3s;
			height: 40px;
			width: 40px;
		}

		.precio-container {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 20px;
			right: 0;
			margin: auto;
			height: 80px;
			justify-content: center;

			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.12px;
			margin: 35% 0 40% 0;
			@media only screen and (max-width: 600px) {
				margin: 28% 0 40% 0;
			}
		}
		.btn-link {
			@media only screen and (max-width: 990px) {
				font-size: 9px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.077px;
			}
		}
		.fecha {
			padding: 0.5rem 0;
			// height: 45%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			place-self: center;
			@media only screen and (max-width: 990px) {
				height: 40%;
			}
		}
	}

	.seccion-blanca {
		font-size: 0.6rem;
		background-color: white;
		margin: 0;
		border-radius: var(--bs-border-radius-xxl);
		padding: 1.5rem 1rem 1rem 1rem;
		color: var(--bs-primary);
		font-weight: 900;

		span {
			font-weight: 400;
		}
	}
	.fecha {
		font-weight: 900;
		color: var(--bs-primary);
	}

	.precio {
		padding: 0.3rem;
		font-weight: 600;
		border-radius: var(--bs-border-radius-xl);
		background: linear-gradient(185deg, #5f5e8f -7.77%, var(--bs-primary) 76.51%);
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
		font-size: 1.5rem;
		color: white;
		top: -0.8rem;
	}

	.tarjeta-principal {
		max-width: 15rem;
		margin: auto;
	}

	@media only screen and (min-width: 962px) {
		.seccion-blanca {
			font-size: 0.8rem;
		}

		.fecha {
			font-size: 1.2rem;
		}
	}
	.text-subTitle-header {
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.12px;
	}

	.header-background {
		padding-top: 14rem;
		padding-bottom: 3rem;
		background-position: 50%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@media only screen and (max-width: 990px) {
			padding-top: 5rem;
		}
	}
</style>
