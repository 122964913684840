import * as AlertUtils from '@/services/utils/AlertUtils';
import router from '@/router';
import i18n from '../../i18n';
import { GruposInformacionViaje } from '@/router/RouterNames';
import * as RouterNames from '@/router/RouterNames';

const { t } = i18n.global;

export const controlErrorValidarCupo = async (error, viajeId, codigoViaje) => {
	const errorStatus = error.response.status;

	switch (errorStatus) {
		case 404:
			AlertUtils.alertWarning(
				t('errors.controlErrorValidarCupo')
				//'No existe el viaje solicitado'
			).then(() => {
				router.push({
					name: RouterNames.GruposReservaAlojamientos,
					params: { viajeId: viajeId },
				});
			});
			break;

		case 410:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.precioCambiado')
			).then(() => {
				const routeParams = {
					name: RouterNames.GruposReservaAlojamientos,
					params: { viajeId: viajeId }
				};

				if (codigoViaje) {
					routeParams.query = { codigo: codigoViaje };
				}

				router.push(routeParams);
			});
			break;

		case 412:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.noAdmiteReserva')
				//'El viaje no admite una reserva nueva'
			).then(() => {
				//TODO ENVIAR AL CATALOGO
				router.push({
					name: RouterNames.GruposReservaAlojamientos,
					params: { viajeId: viajeId },
				});
			});
			break;

		case 500:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.inesperado')
				//t('errors.intentalo')//'Error inesperado. Intente luego'
			).then(() => {
				router.push({
					name: RouterNames.GruposReservaAlojamientos,
					params: { viajeId: viajeId },
				});
			});
			break;
	}
};

export const controlErrorDescartarReserva = error => {
	const errorStatus = error.response.status;

	switch (errorStatus) {
		case 401:
			return t('errors.loginReservaDescartar'); //'Necesitas logearte para descartar la reserva';

		case 403:
			return t('errors.permisosDescartarReserva'); //'No cuenta con los permisos para descartar la reserva';

		case 409:
			return t('errors.descartarReserva'); //'No se puede descartar la reserva';

		case 500:
			return t('errors.intentalo'); //'Error inesperado. Intentelo luego';
	}
};

export const controlErrorCrearGrupoReserva = (error, viajeId) => {
	const errorStatus = error.response.status;

	switch (errorStatus) {
		case 400:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.obligatorios') //'Existen campos obligatorios sin rellenar'
			);
			break;

		case 401:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.loginReservaCrear') //'Necesitas logeare para crear la reserva'
			).then(() => {
				router.push({
					name: 'login',
				});
			});
			break;

		case 403:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.permisosCrearReserva') //'No cuentas con los permisos para crear la reserva'
			).then(() => {
				router.push({
					name: RouterNames.GruposInformacionViaje,
					params: { viajeId: viajeId },
				});
			});
			break;

		case 404:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.noInfoReserva') //'No se ha podido encontrar la informacion de la reserva'
			);
			break;

		case 409:
			if (error.response.data.pendiente) {
				AlertUtils.alertRecuperarDescartarReserva(viajeId);
				return;
			} else {
				AlertUtils.alertAutoCloseWarningWithoutCallback(
					3000,
					t('errors.reserva.alreadyExists')
				);
			}
			break;

		case 412:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.servicios.cuposInsuficientes')
			).then(() => {
				router.go(0);
			});
			break;

		case 423:
			AlertUtils.alertAutoCloseWarningWithoutCallback(2000, error.response.data);
			break;

		case 500:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.intentalo') //'Error inesperado. Intente luego'
			).then(() => {
				router.push({
					name: GruposInformacionViaje,
					params: { viajeId: viajeId },
				});
			});
			break;
	}
};

export const controlErrorCheckNewBookingCompatibility = async (error, viajeId, codigoViaje) => {
	const errorStatus = error.response.status;

	switch (errorStatus) {

		case 404:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.habitacion.codigoNotMatch')
			);
			break;

		case 409:
			if (error.response.data.pendiente) {
				await AlertUtils.alertRecuperarDescartarReserva(viajeId, codigoViaje);
				return;
			} else {
				AlertUtils.alertAutoCloseWarningWithoutCallback(
					3000,
					t('errors.reserva.alreadyExists')
				);
			}
			break;

		case 412:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.experiencia.closed')
			).then(() => {
				router.push({
					name: 'UsuarioProximasReservas',
				});
			});
			break;

		case 500:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.intentalo') //'Error inesperado. Intente luego'
			).then(() => {
				router.push({
					name: RouterNames.GruposInformacionViaje,
					params: { viajeId: viajeId },
				});
			});
			break;
	}
};

export const controlErrorRecuperarReserva = (error, viajeId) => {
	const errorStatus = error.response.status;

	switch (errorStatus) {
		case 401:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.loginReservaCrear') //'Necesitas logeare para crear la reserva'
			).then(() => {
				router.push({
					name: 'login',
				});
			});
			break;
		case 403:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.permisosCrearReserva') //'No cuentas con los permisos para crear la reserva'
			).then(() => {
				router.push({
					name: RouterNames.GruposInformacionViaje,
					params: { viajeId: viajeId },
				});
			});
			break;
		case 404:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.noInfoReserva') //'No se ha podido encontrar la informacion para la reserva'
			).then(() => {
				router.push({
					name: RouterNames.GruposInformacionViaje,
					params: { viajeId: viajeId },
				});
			});
			break;
		case 409:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.noInfoReserva') //'No se ha podido encontrar la informacion para la reserva'
			);
			break;
		case 500:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.intentalo') //'Error inesperado. Intente luego'
			).then(() => {
				router.back(-1);
			});
			break;
	}
};

export const controlErrorRegistrarLoginUsuario = error => {
	const errorStatus = error.response.status;

	switch (errorStatus) {
		case 400:
			AlertUtils.alertAutoCloseWarningWithoutCallback(3000, error.response.data);
			break;
		case 409:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.emailRegistrado') //'El email ya se encuentra registrado'
			);
			break;
		default:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.intentalo') //'Error inesperado, intentelo mas tarde'
			);
	}
};

export const controlErrorRealizarPago = error => {
	if (error == undefined) {
		AlertUtils.alertAutoCloseWarningWithoutCallback(3000, t('errors.camposSinCompletar'));
		return;
	} else {
		const errorStatus = error.response.status;

		switch (errorStatus) {
			case 400:
				AlertUtils.alertAutoCloseWarningWithoutCallback(
					3000,
					t('errors.camposSinCompletar') //'Existe campos sin completar'
				);
				break;
			case 403:
				AlertUtils.alertAutoCloseWarningWithoutCallback(
					3000,
					t('errors.permisosProcesos') //'No cuenta con los permisos para realizar el proceso'
				);
				break;
			case 404:
				AlertUtils.alertAutoCloseWarningWithoutCallback(
					3000,
					t('errors.recursoNoLocalizado') //'El recurso solicitado no ha podido ser localizado'
				);
				break;
			case 409:
				AlertUtils.alertAutoCloseWarningWithoutCallback(
					3000,
					t('errors.conflictoEstadoReserva') //'Existe un conflicto en el estado de la reserva'
				);
				break;
			default:
				AlertUtils.alertAutoCloseWarningWithoutCallback(
					3000,
					t('errors.intentalo') //'Error inesperado, intentelo mas tarde'
				);
		}
	}
};

export const controlErrorActualizarPassword = error => {
	const errorStatus = error.response.status;

	switch (errorStatus) {
		case 400:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.camposSinCompletar') //'Existen campos incompletos'
			);
			break;
		case 401:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.errorContrasenia') //'Error al ingresar la contraseña'
			);
			break;
		default:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.intentalo') //'Error inesperado, intentelo mas tarde'
			);
	}
};

export const controlErrorModificarGrupoReserva = (error, viajeId) => {
	const errorStatus = error.response.status;

	switch (errorStatus) {
		case 400:
			AlertUtils.alertAutoCloseWarningWithoutCallback(3000, error.response.data);
			break;
		case 401:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.loginRequerido') //'Necesita logearse para continuar'
			);
			break;
		case 403:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.permisosProcesos') //'No cuenta con los permisos para realizar el proceso'
			);
			break;
		case 404:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.noInfoReserva') //'No se ha podido encontrar la informacion para la reserva'
			).then(() => {
				router.push({
					name: RouterNames.GruposInformacionViaje,
					params: { viajeId: viajeId },
				});
			});
			break;
		case 409:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.existeReservaViaje') //'Ya existe una reserva para este viaje'
			);
			break;
		case 412:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.servicios.cuposInsuficientes')
			).then(() => {
				router.go(0);
			});
			break;
		default:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.intentalo') //'Error inesperado, intentelo mas tarde'
			);
	}
};

export const controlErrorAlojamientos = (error, viajeId, codigoViaje) => {
	const errorStatus = error.response.status;

	switch (errorStatus) {
		case 400:
			AlertUtils.alertAutoCloseWarningWithoutCallback(3000, error.response.data);
			break;
		case 404:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.noAlojamientos') //'No se encontraron alojamientos'
			);
			break;
		case 409:
			AlertUtils.alertRecuperarDescartarReserva(viajeId, codigoViaje);
			break;
		default:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.intentalo') //'Error inesperado, intentelo mas tarde'
			);
	}
};
//falta validar codigo habitacion

export const controlErrorActualizarDni = error => {
	const errorStatus = error.response.status;
	switch (errorStatus) {
		case 400:
			AlertUtils.alertAutoCloseWarningWithoutCallback(3000, error.response.data);
			break;
		case 401:
			AlertUtils.alertAutoCloseWarningWithoutCallback(3000, error.response.data);
			break;
		default:
			AlertUtils.alertAutoCloseWarningWithoutCallback(
				3000,
				t('errors.intentalo') //'Error inesperado, intentelo mas tarde'
			);
	}
};

export const controlErrorReservarHotel = error => {
	const errorStatus = error.response.status;

	if (errorStatus != 500) {
		AlertUtils.alertAutoCloseWarningWithoutCallback(
			3000,
			t('errors.intentalo') //'Por favor, intente reservar más tarde, en el caso de que el error persista contacte con info@skiandnight.com'
		);
	} else {
		AlertUtils.alertAutoCloseWarningWithoutCallback(
			3000,
			t('errors.intenteReservaTarde') //'Error inesperado, intentelo mas tarde'
		);
	}
};