<template>
	<div>
		<template v-if="viaje.id">
			<div class="card">
				<div class="top-image">
					<img :src="getExperienceImage()" class="image" alt="Trip Image" />
				</div>
				<div class="card">
					<div class="card-body">
						<div class="row w-100 mb-3 m-0">
							<div class="col-8 p-0">
								<div class="nombre-viaje text-primary mb-2 fw-bold">{{ viaje.nombre }}</div>
								<div class="text-muted">
									<LocationIcon :size="20" class="mx-1" />
									{{ viaje.lugarExperiencia?.nombre }}
								</div>
								<div class="text-muted mb-2">
									<CalendaryIcon class="mx-1 calendar-icon" /> {{ formatDate(viaje.fechaInicio) }}
									<template v-if="viaje.fechaInicio !== viaje.fechaFin"> - {{ formatDate(viaje.fechaFin) }} </template>
								</div>
							</div>
							<div class="col-4 text-end p-0">
								<div class="d-flex flex-column align-items-end">
									<div class="buttons-wrapper">
										<div
											v-if="isMisReservas"
											class="button-container pointer"
											:class="{ disabled: isLoadingRecibo }"
											@click="descargarRecibo">
											<div class="icon-wrapper">
												<div v-if="isLoadingRecibo" class="spinner-border spinner-border-sm text-primary" role="status">
													<span class="visually-hidden">{{ msgLoadingRecibo }}</span>
												</div>
												<DocumentIcon v-else :size="50" />
											</div>
											<div class="text-primary mt-1 text-center text-decoration-underline">
												{{ isLoadingRecibo ? msgLoadingRecibo : $t('botones.resumen') }}
											</div>
										</div>

										<div v-if="qrVisible && showQrButton" class="button-container pointer" @click="showModalQr">
											<QrIcon />
											<div class="text-primary mt-1 text-center text-decoration-underline">
												{{ $t('botones.verBono') }}
											</div>
										</div>
									</div>

									<div
										v-if="docVisible && resumenReservas[0]?.hasBonoAdjunto"
										class="d-flex flex-column align-items-end mt-2">
										<div
											class="border-1 border-primary rounded-circle p-2 pointer d-flex flex-column align-items-center"
											@click="descargarPdf">
											<i class="fa-solid fa-file-pdf text-primary fs-4"></i>
											<div class="text-primary mt-2 text-center text-decoration-underline">
												{{ $t('botones.descargarPdf') }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body___content">
							<template v-for="(reserva, index) in resumenReservas" :key="reserva.id">
								<div :class="{ 'opacity-50': reserva.estado === ESTADOS_RESERVA.CANCELADA }">
									<div class="px-3 pt-3">
										<div
											class="text-primary text-bold row"
											:class="{ pointer: !disableCollapse }"
											@click="toggleCollapse(index)">
											<div class="col" :class="{ 'text-danger': reserva.estado === ESTADOS_RESERVA.CANCELADA }">
												<span v-if="!isObject(reserva.usuario)">
													{{ reserva.usuario }}
												</span>
												<template v-else>
													<span v-if="reserva.usuario.nombre.length === 0" style="margin-right: 4px">
														{{ $t('input.field.nombre') }}
													</span>
													<span v-if="reserva.usuario.apellido.length === 0">
														{{ $t('input.field.apellido') }}
													</span>
												</template>
												<span>
													{{ reserva.usuario.nombre }}
													{{ reserva.usuario.apellido }}
												</span>
												<template v-if="reserva.estado === ESTADOS_RESERVA.CANCELADA">
													<span class="badge text-bg-danger ms-3 translate"> {{ reserva.estado }}</span>
													<button type="button" class="btn btn-link" @click="recuperarReserva">
														{{ $t('reserva.recuperarReserva') }}
													</button>
												</template>
											</div>
											<div class="col-3 text-end">
												<button class="btn bg-transparent border-0" :class="{ 'd-none': disableCollapse }">
													<i
														class="fa-solid fa-chevron-up"
														:class="isCollapsed[index] ? 'icon-active' : 'icon-inactive'" />
												</button>
											</div>
										</div>
									</div>
									<div>
										<transition name="collapse">
											<div v-show="!isCollapsed[index]" class="collapse-content">
												<hr class="line-divider mx-3 mt-0" />
												<div class="pb-1 m-2 row text-description">
													<div class="col-8 fw-bold text-primary">
														{{ $t('input.field.paqueteBasico') }}
													</div>
													<div class="col-4 text-end text-primary fw-bold">
														<span v-if="reserva.precioBasico && reserva.precioBasico > 0">
															{{ reserva.precioBasico }}
															{{ agenciaStore.getCurrency }}
														</span>
														<span v-else-if="storeHabitacion.calculaPrecioBaseGrupo && storeHabitacion.calculaPrecioBaseGrupo > 0">
															{{ storeHabitacion.calculaPrecioBaseGrupo }}
															{{ agenciaStore.getCurrency }}
														</span>
													</div>
												</div>
												<div class="row m-2 text-primary text-description">
													<template v-if="alojamiento">
														<div class="col-12 pl-1 pb-1">
															<div>
																{{ alojamiento.nombre }}
															</div>
															<div v-if="alojamiento.estrellas">
																<template v-for="estrella in alojamiento.estrellas" :key="estrella">
																	<i class="fa-regular fa-star"></i>
																</template>
															</div>
															<div>{{ formatDateDDmm(viaje.fechaInicio) }} - {{ formatDateDDmm(viaje.fechaFin) }}</div>
															<template v-if="alojamiento.hasHabitaciones">
																<span v-if="alojamiento.habitacion.tipo">
																	{{ alojamiento.habitacion.tipo.nombre }}
																</span>
																{{ alojamiento.habitacion.numPlazas }}
																{{ $t('general.personas.plural.key') }}
																<div v-if="regimenTraducido" class="text-primary mt-1">
																	{{ $t('general.regimen') }}: {{ regimenTraducido }}
																</div>
															</template>
														</div>
													</template>
													<template v-for="inclu in incluyeFiltrado" :key="inclu.nombre">
														<div class="col-12 pl-1 pb-1 translate">{{ inclu.nombre }} {{ inclu.descripcion }}</div>
													</template>
												</div>
												<template v-for="swc in reserva.serviciosWithCantidad" :key="swc.servicio.id">
													<div v-if="swc.servicio.paqueteBasico" class="row m-2 text-primary text-description">
														<div class="col-12 pl-1 translate d-flex align-items-center">
															{{ swc.servicio.nombre }} ({{ formatDateDDmm(swc.servicio.fechaInicio) }} -
															{{ formatDateDDmm(swc.servicio.fechaFin) }})
															<button
																v-if="
																	isMisReservas &&
																	swc.servicio.seguro &&
																	swc.servicio.subtipo === 'cancelacion' &&
																	reserva.documentacionSeguroCancelacion
																"
																class="btn-recibo ms-2"
																@click="descargarDocumentacion(reserva)">
																<DownloadIcon class="me-1" />
																{{ $t('botones.documentacion') }}
															</button>
														</div>
													</div>

													<div v-else-if="swc.categoriaPaqueteBasico" class="row m-2 text-primary text-description">
														<div class="col-8 pl-1 tachado translate">
															{{ swc.servicioPB.nombre }} ({{ formatDateDDmm(swc.servicioPB.fechaInicio) }} -
															{{ formatDateDDmm(swc.servicioPB.fechaFin) }})
														</div>
														<div class="col-4 text-secondary text-end fw-bold">
															<span>
																- {{ swc.servicio.nulo ? Math.abs(swc.servicio.precio) : swc.servicioPB.precioTeorico }}
																{{ agenciaStore.getCurrency }}
															</span>
														</div>
													</div>
												</template>
												<!-- EXTRAS -->
												<template
													v-if="
														reserva.serviciosWithCantidad.some(
															swc => !swc.servicio.nulo && (!swc.servicio.paqueteBasico || swc.cantidad > 1)
														)
													">
													<div class="row m-2 pt-2 text-primary text-description">
														<div class="col-12 fw-bold">
															{{ $t('reserva.extras') }}
														</div>
													</div>
													<div
														v-for="swc in reserva.serviciosWithCantidad.filter(
															swc => !swc.servicio.nulo && (!swc.servicio.paqueteBasico || swc.cantidad > 1)
														)"
														:key="swc.servicio.id"
														class="row m-2 text-primary text-description">
														<div class="col-9 pl-1 mb-2 d-flex align-items-center">
															<div class="servicio-container">
																<div class="cantidad-wrapper">
																	<div class="cantidad-prefix">
																		<span class="numero">{{
																			swc.servicio.paqueteBasico ? swc.cantidad - 1 : swc.cantidad
																		}}</span
																		><span class="multiplicador">×</span>
																	</div>
																	<div class="separator-line"></div>
																</div>
																<div class="d-flex align-items-center w-100">
																	<div class="servicio-nombre translate">
																		{{ swc.servicio.nombre }} ({{ formatDateDDmm(swc.servicio.fechaInicio) }} -
																		{{ formatDateDDmm(swc.servicio.fechaFin) }})
																	</div>
																	<button
																		v-if="
																			isMisReservas &&
																			swc.servicio.seguro &&
																			swc.servicio.subtipo === 'cancelacion' &&
																			reserva.documentacionSeguroCancelacion
																		"
																		class="btn-recibo ms-2"
																		@click="descargarDocumentacion(reserva)">
																		<DownloadIcon class="me-1" />
																		{{ $t('botones.documentacion') }}
																	</button>
																</div>
															</div>
														</div>
														<div
															v-if="swc.servicio.precio || swc.servicio.paqueteBasico"
															class="col-3 text-end fw-bold">
															{{ calculaPrecioForServicioWithCantidad(swc, false) }} {{ agenciaStore.getCurrency }}
														</div>
													</div>
												</template>
												<!-- COMPLEMENTOS -->
												<template
													v-if="
														(reserva.complementos && reserva.complementos.length > 0) ||
														(reserva.gastos && reserva.gastos.length > 0)
													">
													<div class="row m-2 pt-2 text-secondary text-description">
														<div class="col-12 fw-bold py-2">
															{{ $t('reserva.suplementos.title') }}
														</div>
													</div>
													<div
														v-for="complemento in reserva.complementos"
														:key="complemento.codigo"
														class="row m-2 text-primary text-description">
														<div class="col-9 pl-1 mb-2">
															{{ $t(`reserva.suplementos.${complemento.codigo}`) }}
														</div>
														<div v-if="complemento.importe" class="col-3 text-end fw-bold">
															{{ complemento.importe }} {{ agenciaStore.getCurrency }}
														</div>
													</div>
													<div
														v-for="gasto in reserva.gastos"
														:key="gasto.codigo"
														class="row m-2 text-primary text-description">
														<div class="col-9 pl-1 mb-2">
															{{ $t(`reserva.gastos.${gasto.codigo}`) }}
														</div>
														<div v-if="gasto.importe" class="col-3 text-end fw-bold">
															{{ gasto.importe }} {{ agenciaStore.getCurrency }}
														</div>
													</div>
												</template>
												<!-- TOTALES -->
												<hr class="line-divider mx-3 mt-0" />
												<div class="pb-3 mx-2 row text-description">
													<div v-if="reserva.precioBase > 0" class="col-8 fw-bold text-primary pb-2">
														{{ $t('subtitulo.total').toUpperCase() }}
													</div>
													<div v-if="reserva.precioBase > 0" class="col-4 text-end text-primary fw-bold pb-2">
														{{ reserva.precioBase }} {{ agenciaStore.getCurrency }}
													</div>
													<template v-if="storeHabitacion.getCodigoDescuento">
														<div class="col-12 fw-bold text-primary pb-1">{{ $t('reserva.descuento') }}</div>
														<div class="col-8 px-4 pb-2">
															{{ storeHabitacion.getCodigoDescuento.descripcion }}
															<span class="btn-link" @click="limpiarCodigo">{{ $t('actions.delete') }}</span>
														</div>
														<div class="col-4 text-end text-secondary fw-bold">
															<span> -{{ reserva.descuento }} {{ agenciaStore.getCurrency }} </span>
														</div>
													</template>
													<template
														v-for="tasaExterna in reserva.tasasExternasCalculadas?.filter(t => t.importe > 0)"
														:key="tasaExterna">
														<div class="col-8 fw-bold text-primary">
															{{ $t(`reserva.tasas.externas.${tasaExterna.codigo}`) }}
														</div>
														<div class="col-4 text-end text-primary fw-bold">
															{{ tasaExterna.importe }}{{ agenciaStore.getCurrency }}
														</div>
													</template>
													<template
														v-for="tasaInterna in reserva.tasasInternasCalculadas?.filter(t => t.importe > 0)"
														:key="tasaInterna">
														<div class="col-8 fw-bold text-primary">
															{{ $t(`reserva.tasas.internas.${tasaInterna.codigo}`) }}
														</div>
														<div class="col-4 text-end text-primary fw-bold">
															{{ tasaInterna.importe }}{{ agenciaStore.getCurrency }}
														</div>
													</template>
												</div>
											</div>
										</transition>
									</div>
								</div>
								<hr v-if="index < resumenReservas.length - 1" class="persona-divider" />
							</template>
						</div>
					</div>
					<div class="card-footer text-bold bg-primary text-secondary text-total" style="padding: 13px 18px">
						<div v-if="calculaPrecioTotalReservas(resumenReservas) > 0" class="row total-row">
							<div class="col-7 total-label text-uppercase d-flex align-items-center">
								{{ $t('subtitulo.importeTotal') }}
							</div>
							<div class="col text-end total-amount d-flex align-items-center justify-content-end">
								{{ calculaPrecioTotalReservas(resumenReservas) }} {{ agenciaStore.getCurrency }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script setup>
	import { ref, computed, onMounted, onUnmounted } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { formatDateDDmm, formatDate } from '@/helpers/dateUtiles';
	import { calculaPrecioForServicioWithCantidad, calculaPrecioTotalReservas } from '@/helpers/viajesGrupos';
	import CalendaryIcon from '@/components/Icons/CalendaryIcon.vue';
	import LocationIcon from 'src/components/Icons/LocationIcon.vue';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import QrIcon from '@/components/Icons/QrIcon.vue';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';
	import { calculateTotalPendiente } from '@/services/utils/GrupoUtils';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { createToaster } from '@meforma/vue-toaster';
	import ReservasApiService from '@/services/api/skiandnight/ReservasApiService';
	import DocumentIcon from '@/components/Icons/DocumentIcon.vue';
	import DownloadIcon from '@/components/Icons/DownloadIcon.vue';

	const { t: $t } = useI18n();
	const toaster = createToaster({ position: 'top-right' });

	const isLoadingRecibo = ref(false);
	const msgLoadingRecibo = ref('');

	const props = defineProps({
		precioBase: {
			type: Number || String,
			default: null,
			deprecated: true,
		},
		codigoDescuentoData: {
			type: Object,
			required: false,
			default: null,
			deprecated: true,
		},
		disableCollapse: {
			type: Boolean,
			required: false,
			default: false,
		},

		qrVisible: {
			type: Boolean,
			required: false,
			default: false,
		},
		docVisible: {
			type: Boolean,
			required: false,
			default: false,
		},
		grupoId: {
			type: Number,
			required: false,
			default: null,
		},
		isMisReservas: {
			type: Boolean,
			default: false,
		},
	});

	const emit = defineEmits(['limpiarCodigoDescuento', 'showModalQr', 'recuperarReserva']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();
	const isCollapsed = ref([]);

	const viaje = computed(() => storeHabitacion.getViaje);
	const alojamiento = computed(() => storeHabitacion.getAlojamiento);
	const resumenReservas = computed(() => storeHabitacion.getResumenReservas);

	const totalPending = computed(() => calculateTotalPendiente(resumenReservas.value));

	const showQrButton = computed(() => {
		if (!resumenReservas.value || resumenReservas.value.length === 0) {
			return false;
		}
		const isNotCancelled = resumenReservas.value.some(({ estado }) => estado !== ESTADOS_RESERVA.CANCELADA);
		const hasValidService = resumenReservas.value.some(reserva => {
			return (
				reserva.serviciosWithCantidad &&
				reserva.serviciosWithCantidad.some(
					({ codigosValidacion, servicio }) => codigosValidacion && codigosValidacion.length > 0 && !servicio.nulo
				)
			);
		});
		return isNotCancelled && hasValidService && totalPending.value <= 0;
	});

	const incluyeFiltrado = computed(() => {
		if (viaje.value.incluyes && viaje.value.incluyes.length > 0) {
			return viaje.value.incluyes
				.filter(({ nombre }) => nombre.toLowerCase() !== 'alojamiento')
				.filter(({ isIconoViaje }) => isIconoViaje);
		}
		return [];
	});

	const regimenTraducido = computed(() => {
		const regimen = alojamiento?.value.habitacion?.regimen;
		return regimen ? $t(`reserva.tipo.${regimen}`) : '';
	});

	function toggleCollapse(index) {
		if (!props.disableCollapse) {
			isCollapsed.value[index] = !isCollapsed.value[index];
		}
	}

	function showModalQr() {
		emit('showModalQr');
	}

	function recuperarReserva() {
		emit('recuperarReserva');
	}

	const lastScrollPosition = ref(0);

	function onScroll() {
		if (!props.disableCollapse) {
			const documentHeight = document.documentElement.scrollHeight;
			if (documentHeight > 2000) {
				const scrollY = window.scrollY;
				const windowHeight = window.innerHeight + documentHeight * 0.2;
				const isScrollingDown = scrollY > lastScrollPosition.value;
				const scrollDifference = windowHeight - scrollY;
				if (!isScrollingDown && scrollDifference > 200 && scrollDifference < 500) {
					if (isCollapsed.value[0]) {
						isCollapsed.value.fill(false);
					}
				}
				lastScrollPosition.value = scrollY;
			}
		}
	}

	function limpiarCodigo() {
		emit('limpiarCodigoDescuento');
	}

	function getExperienceImage() {
		return viaje.value.imagenExperiencia || agenciaStore.getImagenFondo;
	}

	async function descargarPdf() {
		if (!props.docVisible) return;

		try {
			const response = await ReservasApiService.getBonoAdjunto(props.grupoId, Number(resumenReservas.value[0].id));

			window.open(response, '_blank');
		} catch (error) {
			console.error('Error al descargar el PDF:', error);
		}
	}

	function isObject(value) {
		return typeof value === 'object';
	}

	async function descargarRecibo() {
		try {
			isLoadingRecibo.value = true;
			msgLoadingRecibo.value = $t('general.pendiente');

			const response = await ReservasApiService.getReciboResumen(props.grupoId, resumenReservas.value[0].id, true);

			if (response?.estado === 'PENDING' || response?.estado === 'IN_PROGRESS') {
				let completed = false;
				while (!completed) {
					msgLoadingRecibo.value = response?.estado === 'PENDING' ? $t('general.pendiente') : $t('general.ejecutando');

					await new Promise(resolve => setTimeout(resolve, 5000));

					const pollResponse = await ReservasApiService.getReciboResumen(
						props.grupoId,
						resumenReservas.value[0].id,
						false
					);

					if (pollResponse?.estado === 'COMPLETED' && pollResponse?.url) {
						window.open(pollResponse.url, '_blank');
						completed = true;
					} else if (pollResponse?.estado === 'FAILED') {
						toaster.error($t('errors.generacion'));
						completed = true;
					}
				}
			} else if (response?.estado === 'COMPLETED' && response?.url) {
				window.open(response.url, '_blank');
			} else if (response?.estado === 'FAILED') {
				toaster.error($t('errors.generacion'));
			}
		} catch (error) {
			console.error('Error al descargar el recibo:', error);
			toaster.error($t('errors.descarga'));
		} finally {
			isLoadingRecibo.value = false;
			msgLoadingRecibo.value = '';
		}
	}

	function descargarDocumentacion(reserva) {
		if (reserva?.documentacionSeguroCancelacion) {
			window.open(reserva.documentacionSeguroCancelacion, '_blank');
		}
	}

	onMounted(() => {
		if (!props.disableCollapse) {
			isCollapsed.value = resumenReservas.value.map(() => false);
			window.addEventListener('scroll', onScroll);
		}
	});

	onUnmounted(() => {
		if (!props.disableCollapse) {
			window.removeEventListener('scroll', onScroll);
		}
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.card-image {
		width: 100%;
		border-radius: var(--bs-border-radius-xl);
		box-shadow: 0px 1px 2px 1px rgba(33, 36, 68, 0.25), 0px 0px 10px 0px rgba(33, 36, 68, 0.25);
		padding-bottom: 0.2rem;

		&__footer {
			font-size: 0.8em;
		}

		.card-image__header {
			position: relative;

			&__background {
				overflow: hidden;
				border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0;

				img {
					width: 100%;
					height: 100px;
					opacity: 0.5;
					object-fit: cover;
				}
			}

			&__share {
				position: absolute;
				top: 10px;
				right: 10px;
			}
		}

		.card-image__body {
			margin-top: -1rem;
		}
	}

	.card {
		border-radius: var(--bs-border-radius-xl) !important;
		background-color: #f9f7f8 !important;
		box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1) !important;
		border: 0px;

		.card-body {
			background-color: #f9f7f8;
			z-index: 1000;
			padding: 21px 21px 0 21px;
			border-radius: var(--bs-border-radius-xl) !important;

			&___content {
				border-radius: var(--bs-border-radius-xl) var(--bs-border-radius-xl) 0 0 !important;
				background-color: white;
			}

			.title {
				font-size: 22px;
				font-weight: 700;
			}

			.sub-title {
				color: #515151;
				font-size: 15px;
				font-weight: 400;
			}
		}

		.card-footer {
			border-radius: 0 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl);
			border-top: 0px;

			.rounded-circle {
				width: 30px;
				height: 30px;
				padding: 5px;
				margin-left: 12px;
			}
		}
	}

	.collapse-content {
		font-size: 15px;
		font-weight: 400;
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.text-bold {
		font-size: 15px;
		font-weight: 700 !important;
	}

	.btn-link {
		font-size: 11px;
		font-weight: 400;
		letter-spacing: 0.12px;
		cursor: pointer;
	}

	.text-description {
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.12px;
	}

	.text-total {
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 0.12px;
	}

	.pl-1 {
		padding-left: 20px;
	}

	.top-image {
		@extend .position-relative;
		margin-bottom: -30px;
		z-index: 1000;

		.image {
			@extend .card-img-top, .d-block, .rounded-top-4, .object-fit-cover;
			height: 130px;
		}
	}

	.collapse-enter-active,
	.collapse-leave-active {
		transition: height 3s ease;
	}

	.collapse-enter-from,
	.collapse-leave-to {
		height: 0;
		overflow: hidden;
	}

	.buttons-wrapper {
		display: flex;
		gap: 8px;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			width: 100%;
		}
	}

	.button-container {
		background-color: #fff;
		border-radius: 8px;
		padding: 8px;
		border: 1px solid rgba(var(--bs-primary-rgb), 0.25);
		height: 91px;
		width: 86px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: 768px) {
			width: 86px;
			margin: 0 auto;
		}

		.icon-wrapper {
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.text-primary {
			font-size: 14px;
			font-weight: 600;
		}

		&:hover {
			background-color: rgba(var(--bs-primary-rgb), 0.05);
		}
	}

	.icono-ubicacion {
		width: 20px;
		height: 20px;
	}

	.servicio-container {
		display: flex;
		position: relative;
	}

	.cantidad-wrapper {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		min-width: 40px;
		margin-left: -20px;
	}

	.cantidad-prefix {
		color: var(--bs-secondary);
		font-weight: 700;
		font-size: 13px;
		text-align: right;
		width: 28px;
		white-space: nowrap;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.numero {
			display: inline-block;
			text-align: right;
		}

		.multiplicador {
			display: inline-block;
			margin-left: 1px;
		}
	}

	.separator-line {
		border-left: 2px solid var(--bs-secondary);
		min-height: 100%;
		align-self: stretch;
		margin: 0 4px;
	}

	.servicio-nombre {
		line-height: 1.4;
		flex: 1;
	}

	:deep(.card, .top-image, .image, .sticky-card, .card-body, ) {
		border-radius: var(--bs-border-radius-xl) !important;
	}

	.total-row {
		display: flex;
		align-items: center;
		min-height: 40px;
	}

	.total-label {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		text-transform: uppercase;
	}

	.total-amount {
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
	}

	:deep(.calendar-icon) {
		color: var(--bs-primary);
	}

	.persona-divider {
		margin: 0;
		border-top: 1px solid var(--bs-primary);
		opacity: 1;
	}

	.tachado {
		text-decoration: line-through;
	}

	.nombre-viaje {
		font-size: 24px !important;
	}

	@media screen and (max-width: 768px) {
		.nombre-viaje {
			font-size: 16px !important;
		}
	}

	.btn-recibo {
		border: none;
		background: none;
		color: var(--bs-primary);
		font-size: 11px;
		font-weight: 600;
		text-decoration: underline;
		cursor: pointer;
		padding: 0;
		display: flex;
		align-items: center;
	}
</style>
