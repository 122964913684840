<template>
	<div>
		<div
			id="desktop-footer"
			class="footer text-white bg-primary"
			:class="{ 'footer-san': storeGlobal.isSeaAndNightUrl }"
			:style="
				storeGlobal.isSeaAndNightUrl ? {} : { 'background-image': 'url(' + storeGlobal.valores.img_footer + ')' }
			">
			<template v-if="storeGlobal.permisos.nosotros.showed || storeGlobal.permisos.rrss.showed">
				<div class="row d-flex w-100 m-auto justify-content-center py-0 py-md-5">
					<div
						v-if="storeGlobal.permisos.nosotros.showed"
						class="order-2 order-md-first col-12 col-md-3 text-center text-md-start my-4 my-md-0">
						<div class="fs-4 fw-bold h5">
							{{ footerData.us.title }}
						</div>
						<template v-for="usItem in footerData.us.items" :key="usItem">
							<div v-if="usItem.show || usItem.show == undefined" class="item h6">
								<template v-if="usItem.link">
									<a :href="usItem.link" target="_blank">
										{{ usItem.label }}
									</a>
								</template>
								<template v-else>
									<router-link :to="{ name: usItem.routeName }">
										{{ usItem.label }}
									</router-link>
								</template>
							</div>
						</template>
					</div>
					<div v-if="storeGlobal.permisos.rrss.showed" class="order-1 order-md-last col-12 col-md-3 my-4 my-md-0">
						<p class="fw-bold mb-2 text-center text-md-start h5">
							{{ footerData.social.title }}
						</p>
						<div class="row w-75 mx-auto mx-md-0">
							<template v-for="social in footerData.social.items" :key="social">
								<div class="col col-sm-2 item-social">
									<a :href="social.link" target="_blank" :aria-label="social.ariaLabel">
										<i :class="social.icon"></i>
									</a>
								</div>
							</template>
						</div>
					</div>
				</div>
			</template>

			<div class="container pb-4 copyright d-flex text-center">
				<div v-if="storeGlobal.permisos.sponsors.showed" class="d-none d-md-block"></div>
				<div class="col-12 col-md-6 content">
					<div class="row">
						<div class="col">
							<div v-if="windowLocation.includes('bodosound')" class="item">
								BODO FESTIVALS S.L. © {{ new Date().getFullYear() }}
							</div>

							<div v-else class="itemFirstFooter">
								<a href="https://mygocu.com"> MYGOCU </a>
								© {{ new Date().getFullYear() }}
							</div>
						</div>
					</div>
					<div class="row justify-content-center text-center align-items-center d-flex flex-column flex-md-row pb-3">
						<div class="col-12 col-md-auto mb-md-0">
							<div class="item">
								<a class="text-white" @click="handleAvisoLegalClick">
									{{ $t('footer.copyright.legal') }}
									<span class="verticalBars d-none d-md-inline">|</span>
								</a>
							</div>
						</div>
						<div class="col-12 col-md-auto">
							<div v-if="!windowLocation.includes('xplorers')" @click="goCondicionesGenerales">
								<div class="item">
									{{ $t('footer.copyright.conditions') }}
									<span class="verticalBars d-none d-md-inline">|</span>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-auto">
							<div @click="goPoliticaCookies">
								<div class="item">
									{{ $t('footer.copyright.cookies') }}
									<span class="verticalBars d-none d-md-inline">|</span>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-auto">
							<div @click="goFAQs">
								<div class="item">
									{{ $t('footer.copyright.faqs') }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { appGlobalStore } from 'src/store_pinia/app';
	import { facebook, instagram, twitter, youtube, linkedin, tiktok } from 'src/constants/socialMedias.js';
	import { useI18n } from 'vue-i18n';
	import { computed } from 'vue';
	import { BlogLanding, FAQS, TrabajaConNosotros } from '@/router/RouterNames';
	import router from '@/router';
	import HomeApiService from '@/services/api/skiandnight/HomeApiService';

	const { t } = useI18n();
	const storeGlobal = appGlobalStore();
	const windowLocation = computed(() => window.location.origin);
	const bodoDomain = 'https://app.bodosound.com';

	const footerData = computed(() => {
		return {
			us: {
				title: t('footer.us.title'),
				items: [
					{
						label: t('footer.us.work'),
						routeName: TrabajaConNosotros,
						show: storeGlobal.permisos.nosotros.showed,
					},
					{
						label: t('footer.us.blog'),
						routeName: BlogLanding,
						show: storeGlobal.permisos.blog.showed,
					},
				],
			},
			social: {
				title: t('footer.social'),
				items: [
					{
						link: facebook,
						ariaLabel: 'Link Ski&Night Facebook',
						icon: 'fa-brands fa-facebook-f fa-xl',
					},
					{
						link: instagram,
						ariaLabel: 'Link Ski&Night Instragram',
						icon: 'fa-brands fa-instagram fa-xl',
					},
					{
						link: twitter,
						ariaLabel: 'Link Ski&Night Twitter',
						icon: 'fa-brands fa-twitter fa-xl',
					},
					{
						link: linkedin,
						ariaLabel: 'Link Ski&Night Linkedin',
						icon: 'fa-brands fa-linkedin fa-xl',
					},
					{
						link: tiktok,
						ariaLabel: 'Link Ski&Night Tiktok',
						icon: 'fa-brands fa-tiktok fa-xl',
					},
					{
						link: youtube,
						ariaLabel: 'Link Ski&Night Youtube',
						icon: 'fa-brands fa-youtube fa-xl',
					},
				],
			},
		};
	});

	function goCondicionesGenerales() {
		if (window.location.origin === bodoDomain) {
			window.open('https://bodosound.com/terminos-y-condiciones-del-servicio/', '_blank');
		} else {
			router.push({ name: 'PoliticaPrivacidad' });
		}
	}

	function goPoliticaCookies() {
		if (window.location.origin === bodoDomain) {
			window.open('https://bodosound.com/privacy-policy/', '_blank');
		} else {
			router.push({ name: 'PoliticaCookies' });
		}
	}

	function goFAQs() {
		router.push({ name: FAQS });
	}

	async function handleAvisoLegalClick() {
		try {
			const response = await HomeApiService.getAvisoLegal();
			if (response) {
				const decodedUrl = response.replace(/\\/g, '');
				window.open(decodedUrl, '_blank');
				return;
			}
		} catch (error) {
			console.error('Error getting legal notice:', error);
		}
	}
</script>

<style lang="scss" scoped>
	.footer {
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		.content,
		.copyright {
			max-width: 1440px;
			margin: auto;
		}
	}

	.footer-san {
		width: 100%;
		background-image: url('src/assets/fotos/FooterSeaAndNight.webp');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		.content,
		.copyright {
			max-width: 1440px;
			margin: auto;
		}
	}

	.item-social {
		display: flex;
		align-items: center;
		justify-content: center;
		a {
			i {
				color: white;
			}
		}
		padding: 8px;
	}

	.item {
		margin-top: 0.8rem !important;
		cursor: pointer;
	}

	.itemFirstFooter {
		padding-top: 2.5rem;
	}

	.verticalBars {
		margin-left: 1.1rem;
	}
</style>
