import { defineStore } from 'pinia';

export const reservaHoteles = defineStore('reservaHoteles', {
	state: () => ({
		informacionHabitaciones: [],
		serviciosSelecionados: {},
		totalReserva: 0,
		reservaCreada: null,
	}),

	actions: {
		setServiciosHabitaciones(listaHabitaciones) {
			let totalHabitacionesServicios = [];
			listaHabitaciones.habitaciones.map((habitacion, indexHabitacion) => {
				this.serviciosSelecionados[`habitacion${indexHabitacion}`] = {};

				let informacionMiembro = habitacion.miembrosHabitacion;

				let informacionMiembrosServiciosPredefinidos = [];

				informacionMiembro.map((miembroHabitacion, indexPersona) => {
					let agrupacionesServiciosMiembroHabitacion = miembroHabitacion.agrupacionesServicios;
					this.serviciosSelecionados[`habitacion${indexHabitacion}`][`persona${indexPersona}`] = {
						edad: miembroHabitacion.edad,
					};

					let informacionMiembroAux = {
						agrupaciones: {},
						serviciosPredefinidos: {},
						edad: miembroHabitacion.edad,
						soyYo: false,
					};

					agrupacionesServiciosMiembroHabitacion.forEach(agrupacionServicios => {
						let servicioPredefinido = agrupacionServicios.servicios.find(({ nulo }) => nulo);
						if (agrupacionServicios.paqueteBasico) {
							servicioPredefinido = agrupacionServicios.servicios.find(({ paqueteBasico }) => paqueteBasico);
						}

						this.serviciosSelecionados[`habitacion${indexHabitacion}`][`persona${indexPersona}`][
							agrupacionServicios.id
							] = servicioPredefinido;
						this.serviciosSelecionados[`habitacion${indexHabitacion}`][`persona${indexPersona}`]['edad'] =
							miembroHabitacion.edad;
						this.serviciosSelecionados[`habitacion${indexHabitacion}`][`persona${indexPersona}`]['soyYo'] =
							indexHabitacion == 0 && indexPersona == 0;

						informacionMiembroAux.soyYo = indexHabitacion == 0 && indexPersona == 0;
						informacionMiembroAux.serviciosPredefinidos[agrupacionServicios.id] = servicioPredefinido;
						informacionMiembroAux.agrupaciones[agrupacionServicios.id] = {
							id: agrupacionServicios.id,
							nombre: agrupacionServicios.nombre,
							tipo: agrupacionServicios.tipo,
							subtipo: agrupacionServicios.subtipo,
							servicios: agrupacionServicios.servicios,
							filtros: agrupacionServicios.filtrosServicios
						};
					});

					informacionMiembrosServiciosPredefinidos.push(informacionMiembroAux);
				});
				totalHabitacionesServicios.push(informacionMiembrosServiciosPredefinidos);
			});
			this.informacionHabitaciones = totalHabitacionesServicios;
		},
		setServiciosSeleccionados(serviciosSeleccionados) {
			this.serviciosSelecionados = serviciosSeleccionados;
		},
		setTotalReserva(totalReserva) {
			this.totalReserva = totalReserva;
		},
		setSoyYo(indexHabitacion, indexPersona) {
			Object.keys(this.serviciosSelecionados).map((keyHabitacion, indexHabitacionAux) => {
				Object.keys(this.serviciosSelecionados[keyHabitacion]).map((keyPersona, indexPersonaAux) => {
					this.serviciosSelecionados[keyHabitacion][keyPersona].soyYo =
						indexHabitacion == indexHabitacionAux && indexPersona == indexPersonaAux;
				});
			});
		},
		setReservaCreada(reserva) {
			this.reservaCreada = reserva;
		},
	},

	getters: {
		nombresIncompletos(state) {
			return Object.keys(state.serviciosSelecionados).some(keyHabitacion => {
				return Object.keys(state.serviciosSelecionados[keyHabitacion]).some(keyPersona => {
					return !state.serviciosSelecionados[keyHabitacion][keyPersona].nombre;
				});
			});
		},
	},
});
