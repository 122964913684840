<template>
	<div class="carousel-container">
		<button v-if="sortedViajes.length > 1 && !isMobile && !isAtStart" class="button-overlay start-0" @click="prev">
			<span class="me-1 mb-2">
				<LeftCaretIcon/>
			</span>
		</button>
		<Carousel ref="carouselRef" v-bind="config" @init="handleInit" @slide-end="handleSlideEnd">
			<Slide v-for="viaje in sortedViajes" :key="viaje.id" class="py-4">
				<TripCard :viaje-resumen-dto="viaje" />
			</Slide>
		</Carousel>
		<button v-if="sortedViajes.length > 1 && !isMobile && !isAtEnd" class="button-overlay end-0" @click="next">
			<span class="ms-1 mb-2">
				<RightCaretIcon/>
			</span>
		</button>
	</div>
</template>

<script setup>
	import { Carousel, Slide } from 'vue3-carousel';
	import TripCard from '@/components/card/TripCard/TripCard.vue';
	import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
	import 'vue3-carousel/dist/carousel.css';
	import LeftCaretIcon from '@/components/Icons/LeftCaretIcon.vue';
	import RightCaretIcon from '@/components/Icons/RightCaretIcon.vue';

	const carouselRef = ref(null);
	const isMobile = ref(false);

	const isAtStart = ref(true);
	const isAtEnd = ref(false);

	const config = ref({
		itemsToShow: 4,
		itemsToScroll: 1,
		snapAlign: 'start',
		wrapAround: false,
		touchDrag: true,
		mouseDrag: true,
	});

	const props = defineProps({
		listaViajes: {
			type: Array,
			default: () => [],
		},
		tiposViaje: {
			type: Array,
			require: false,
			default: () => [],
		},
	});

	const sortedViajes = computed(() => {
		return [...props.listaViajes].sort((a, b) => new Date(a.fechaInicio) - new Date(b.fechaInicio));
	});

	function next() {
		carouselRef.value?.next();
	}

	function prev() {
		carouselRef.value?.prev();
	}

	function handleInit() {
		checkArrows();
	}

	function handleSlideEnd({ currentSlideIndex, slidesCount }) {
		isAtStart.value = currentSlideIndex === 0;
		isAtEnd.value =
			currentSlideIndex === slidesCount - config.value.itemsToShow ||
			currentSlideIndex >= slidesCount - config.value.itemsToShow;
	}

	function checkArrows() {
		const currentIndex = carouselRef.value.modelValue ?? 0;
		isAtStart.value = currentIndex === 0;
		isAtEnd.value = sortedViajes.value.length === config.value.itemsToShow;
	}

	function updateMobileStatus() {
		isMobile.value = window.innerWidth < 1024;
	}

	function updateConfigBasedOnScreenWidth() {
		const screenWidth = window.innerWidth;

		if (screenWidth < 400) {
			config.value.itemsToShow = 1.2;
			config.value.snapAlign = 'center';
		} else if (screenWidth < 600) {
			config.value.itemsToShow = 1.3;
			config.value.snapAlign = 'center';
		} else if (screenWidth < 1024) {
			config.value.itemsToShow = 2;
			config.value.snapAlign = 'center';
		} else if (screenWidth < 1300) {
			config.value.itemsToShow = 3;
			config.value.snapAlign = 'start';
		} else {
			config.value.itemsToShow = 4;
			config.value.snapAlign = 'start';
		}
	}

	onMounted(function () {
		updateMobileStatus();
		updateConfigBasedOnScreenWidth();
		window.addEventListener('resize', updateMobileStatus);
		window.addEventListener('resize', updateConfigBasedOnScreenWidth);
	});

	onBeforeUnmount(function () {
		window.removeEventListener('resize', updateMobileStatus);
		window.removeEventListener('resize', updateConfigBasedOnScreenWidth);
	});
</script>

<style scoped>
	.carousel-container {
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	.button-overlay {
		position: absolute;
		top: 44%;
		transform: translateY(-50%);
		background-color: var(--bs-primary);
		border-radius: 50%;
		width: 1em;
		height: 1em;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: 40px;
		cursor: pointer;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
		border: none;
		z-index: 10;
	}

	.button-overlay:hover {
		background-color: #4a4a6a;
	}
</style>
