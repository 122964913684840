<template>
	<div v-if="storeHabitacion.getAgrupacionesServicios.length > 0">
		<div>
			<CardPersonaReserva
				:numero-plazas="informacionGrupo.habitacion?.numPlazas"
				:idx-reserva="idxReserva"
				:agrupaciones-servicios="storeHabitacion.getAgrupacionesServicios"
				:has-modificar-persona="false"
				@habilitar-modificar="habilitarModificar = true" />
		</div>
	</div>
</template>

<script setup>
	import { onBeforeMount, ref } from 'vue';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import CardPersonaReserva from '@/modules/viajesGrupos/components/CardPersonaReserva.vue';
	import { storeToRefs } from 'pinia/dist/pinia';

	const props = defineProps({
		informacionGrupo: {
			type: Object,
			default: null,
		},
		idxReserva: {
			type: Number,
			default: null,
		},
	});

	const habilitarModificar = ref(false);
	const storeHabitacion = informacionHabitacion();
	const { reservas } = storeToRefs(storeHabitacion);
	const reserva = reservas.value[props.idxReserva];

	function init() {
		reserva['habilitarReserva'] = true;
	}

	onBeforeMount(() => init());
</script>

<style scoped>
	.form-check-input:checked {
		background-color: var(--bs-secondary);
		border-color: var(--bs-secondary);
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}
</style>
