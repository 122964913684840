<template>
	<BuscadorGruposDesktopHome
		:informacion-busqueda-grupos="filterInformationStore"
		:filtro-viajes="filtroViajes"
		@setear-destino-buscador="setDestiny"
		@setear-fecha-buscador="setFechasViajes"
		@setear-grupos-buscador="setGroups"
		@limpiar-destino-buscador="clearDestinos"
		@limpiar-fecha-buscador="clearFechas"
		@do-busqueda-viajes-grupos="goToCatalogoViajesGrupos" />

	<BuscadorGruposMobileHome
		:informacion-busqueda-grupos="filterInformationStore"
		:filtro-viajes="filtroViajes"
		@setear-destino-buscador="setDestiny"
		@setear-fecha-buscador="setFechasViajes"
		@setear-grupos-buscador="setGroups"
		@limpiar-destino-buscador="clearDestinos"
		@limpiar-fecha-buscador="clearFechas"
		@do-busqueda-viajes-grupos="goToCatalogoViajesGrupos" />
</template>

<script setup>
	import BuscadorGruposDesktopHome from './buscadorGrupos/BuscadorGruposDesktopHome.vue';
	import BuscadorGruposMobileHome from './buscadorGrupos/BuscadorGruposMobileHome.vue';
	import { ViajesGrupoCatalogo } from '@/router/RouterNames';
	import { computed, onBeforeMount } from 'vue';
	import { ref } from 'vue';
	import { buscadorGrupos } from '@/store_pinia/buscadorGrupos';
	import router from '@/router';
	import { useFiltroGruposStore } from '@/store_pinia/filtros/filtroGrupos';
	import moment from 'moment';
	const filterInformationStore = buscadorGrupos();
	const route = ref(router.currentRoute);
	const filtroGruposStore = useFiltroGruposStore()

	const filtroViajes = computed(() => filterInformationStore.filtrosViajes);

	function setDestiny(lugarExperiencia) {
		filterInformationStore.setLugarExperiencia(lugarExperiencia);
	}

	function setFechasViajes(date) {
		filterInformationStore.setDateBuscadorGrupos(date);
	}

	function setGroups(group) {
		filterInformationStore.setTipoViajeBuscadorGrupos(group);
	}

	function clearDestinos() {
		filterInformationStore.clearDestinyBuscadorGrupos();
	}

	function clearFechas() {
		filterInformationStore.clearDateBuscadorGrupos();
	}

	function goToCatalogoViajesGrupos() {
		if (filterInformationStore.lugarExperiencia) {
			filtroGruposStore.setFiltroLugaresExperiencia([{ ...filterInformationStore.lugarExperiencia, selected: true }]);
		}
		if (filterInformationStore.tipoViaje) {
			filtroGruposStore.setFiltroTipoViaje([{ ...filterInformationStore.tipoViaje, selected: true }]);
		}
		if (filterInformationStore.date) {
			filtroGruposStore.setFiltroMonth(moment(filterInformationStore.date));
		}
		router.push({
			name: ViajesGrupoCatalogo,
		});
	}

	onBeforeMount(() => {
		if (route.value.name !== ViajesGrupoCatalogo) {
			filterInformationStore.setFilterInformation();
		}
	});

</script>
