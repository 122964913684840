<template>
	<section class="header">
		<div class="box-image">
			<img :src="getExperienceImage()" class="image" />
			<div v-if="mostrarBotonCompartirViaje" class="share-box">
				<button class="share-button" @click="compartirLinkComercial(viajeResumenDto.id)">
					<share-icon class="share-icon" />
				</button>
			</div>
		</div>
	</section>
</template>

<script setup>
	import { computed } from 'vue';
	import { createToaster } from '@meforma/vue-toaster';
	import { useStore } from 'vuex';
	import { useI18n } from 'vue-i18n';

	import CookiesUtils from '@/services/utils/CookiesUtils';
	import ShareIcon from '@/components/Icons/ShareIcon.vue';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	const store = useStore();
	const { t } = useI18n();
	const toaster = createToaster({ position: 'top-right' });
	const agenciaStore = agenciaStoreModule();

	// eslint-disable-next-line no-unused-vars
	const props = defineProps({
		viajeResumenDto: {
			type: Object,
			required: true,
		},
	});

	const mostrarBotonCompartirViaje = computed(
		() =>
			store.state.login.isLogged &&
			CookiesUtils.getUsuarioFromLocalStorage().roles.some(value => value == 'ROLE_COMERCIAL')
	);

	function getExperienceImage() {
		return props.viajeResumenDto.imagenExperiencia || agenciaStore.getImagenFondo;
	}

	function compartirLinkComercial(viajeCompartir) {
		navigator.clipboard
			.writeText(
				window.location.origin +
					'/grupos/viajes/' +
					viajeCompartir +
					'?comercialId=' +
					CookiesUtils.getUsuarioFromLocalStorage().id
			)
			.then(() => {
				toaster.success(t('general.codigoCopiado'));
			});
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/san.scss';

	.trip-card {
		.header {
			.box-image {
				@extend .position-relative;

				.image {
					@extend .card-img-top, .rounded-top-4, .d-block, .object-fit-cover;
					height: 150px;
					@include media-breakpoint-up(md) {
						height: 170px;
					}
				}

				.share-box {
					@extend .position-absolute, .top-0, .end-0, .mt-3, .me-3, .rounded-circle;
					transition: transform 0.35s ease-in-out;

					&:hover {
						transform: scale(1.2);
						box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
					}

					.share-button {
						@extend .btn,
							.bg-secondary,
							.border-0,
							.p-0,
							.rounded-circle,
							.shadow,
							.text-primary,
							.d-flex,
							.justify-content-center,
							.align-items-center;
						height: 35px;
						width: 35px;

						&:hover {
							@extend .bg-primary;

							.icon-share {
								.icon-share__path {
									fill: var(--bs-secondary);
								}
							}
						}
					}

					.icon-share {
						.icon-share__path {
							fill: var(--bs-primary);
						}
					}
				}
			}
		}
	}
</style>
