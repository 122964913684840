<template>
	<div class="p-3">
		<div class="row">
			<div
				v-for="agrupacion in informacionMiembro.agrupaciones" :key="agrupacion.id"
				class="col-12">
				<template v-if="agrupacion.tipo === 'alquiler' || agrupacion.tipo === 'clases'">
					<select-service-subfilter
						:agrupacion="agrupacion"
						:servicio-predefinido="informacionMiembro.serviciosPredefinidos[agrupacion.id]"
						@servicio-actualizado="actualizarServicio($event)" />
				</template>
				<template v-else>
					<select-service-individual
						:agrupacion="agrupacion"
						:servicio-predefinido="informacionMiembro.serviciosPredefinidos[agrupacion.id]"
						@servicio-actualizado="actualizarServicio($event)" />
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import SelectServiceIndividual from '@/components/select/selectService/SelectServiceIndividual.vue';
	import SelectServiceSubfilter from '@/components/select/selectService/SelectServiceSubfilter.vue';

	export default defineComponent({
		components: {
			SelectServiceIndividual,
			SelectServiceSubfilter,
		},
		props: {
			indexPersona: {
				type: String,
				default: null,
			},
			informacionMiembro: {
				type: Object,
				default: null,
			},
			idViaje: {
				type: Number,
				default: null,
			},
		},
		emits: ['serviciosPersonaActualizado'],
		setup(props, { emit }) {
			let serviciosPersona = {};

			serviciosPersona['persona' + props.indexPersona] = {};

			const actualizarServicio = $event => {
				let keys = Object.keys($event);
				keys.map(key => {
					if ($event[key] == null) return;
					serviciosPersona['persona' + props.indexPersona][key] = $event[key];
				});
				emit('serviciosPersonaActualizado', serviciosPersona);
			};
			return { actualizarServicio };
		},
	});
</script>

<style scoped></style>
