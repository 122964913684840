<template>
	<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.39964 9.55827L0.469688 2.7195C-0.160729 2.09738 -0.160729 1.08871 0.469688 0.466592C1.10011 -0.155531 2.12222 -0.155531 2.75263 0.466592L10.8241 8.43182C11.4545 9.05394 11.4545 10.0626 10.8241 10.6847L2.75263 18.6499C2.12222 19.2721 1.10011 19.2721 0.469688 18.6499C-0.160729 18.0278 -0.160729 17.0192 0.469688 16.397L7.39964 9.55827Z"
			fill="white" />
		<mask
			id="mask0_2654_19185"
			style="mask-type: luminance"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="12"
			height="20">
			<path
				d="M7.39964 9.55827L0.469688 2.7195C-0.160729 2.09738 -0.160729 1.08871 0.469688 0.466592C1.10011 -0.155531 2.12222 -0.155531 2.75263 0.466592L10.8241 8.43182C11.4545 9.05394 11.4545 10.0626 10.8241 10.6847L2.75263 18.6499C2.12222 19.2721 1.10011 19.2721 0.469688 18.6499C-0.160729 18.0278 -0.160729 17.0192 0.469688 16.397L7.39964 9.55827Z"
				fill="white" />
		</mask>
		<g mask="url(#mask0_2654_19185)"></g>
	</svg>
</template>
