<template>
	<article class="trip-card pointer">
		<TripCardHeader
			:viaje-resumen-dto="viajeResumenDto"
			@mousedown="startDrag"
			@mousemove="onDrag"
			@mouseup="handleMouseUp"
			@click="handleCardClick"/>

		<TripCardBody
			:viaje-resumen-dto="viajeResumenDto"
			:hide-services="hideServices"
			@mousedown="startDrag"
			@mousemove="onDrag"
			@mouseup="handleMouseUp"
			@click="handleCardClick"/>

		<TripCardFooter
			v-if="!hideFooter"
			:viaje-resumen-dto="viajeResumenDto"/>
	</article>
</template>

<script setup>
	import TripCardHeader from './TripCardHeader.vue';
	import TripCardBody from './TripCardBody.vue';
	import TripCardFooter from '@/components/card/TripCard/TripCardFooter.vue';
	import { useRoute, useRouter } from 'vue-router';
	import { ref } from 'vue';
	import { GruposInformacionViaje } from '@/router/RouterNames';

	const props = defineProps({
		viajeResumenDto: {
			type: Object,
			required: true,
		},
		hideServices: {
			type: Boolean,
			required: false,
			default: () => false,
		},
		hideFooter: {
			type: Boolean,
			required: false,
			default: () => false,
		},
	});

	const router = useRouter();
	const currentGroup = useRoute().query.grupo;

	const startX = ref(0);
	const startY = ref(0);
	const hasDragged = ref(false);
	const isClickable = ref(true);

	function startDrag(event) {
		startX.value = event.clientX;
		startY.value = event.clientY;
		hasDragged.value = false;
		isClickable.value = true;
	}

	function onDrag(event) {
		const deltaX = Math.abs(event.clientX - startX.value);
		const deltaY = Math.abs(event.clientY - startY.value);

		if (deltaX > 5 || deltaY > 5) {
			hasDragged.value = true;
		}
	}

	function handleMouseUp(event) {
		isClickable.value = false;

		if (!hasDragged.value && !event.target.closest('.button-collapse')) {
			goToInfo(props.viajeResumenDto.id);
		}
	}

	function handleCardClick(event) {
		if (isClickable.value && !event.target.closest('.button-collapse')) {
			goToInfo(props.viajeResumenDto.id);
		}
	}

	function goToInfo(viajeId) {
		router.push({
			name: GruposInformacionViaje,
			params: { viajeId: viajeId },
			query: { grupo: currentGroup },
		});
	}
</script>

<style lang="scss">
	@import '~@/assets/scss/san.scss';

	.trip-card {
		@extend .card, .rounded-4, .border-0;
		background: white;
		box-shadow: 0 1px 4px 1px rgba(var(--bs-primary-rgb), 0.25);
		width: 280px;
		min-width: 280px;

		@include media-breakpoint-up(md) {
			width: 290px;
		}

		.white-space {
			width: 100%;
			height: 25px;

			@include media-breakpoint-up(md) {
				height: 26px;
			}
		}

		.rotate-icon {
			transform: rotate(180deg);
			transition: transform 0.15s ease;
		}

		@keyframes marquee {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(-66.6%);
			}
		}
	}
</style>
