<template>
	<div class="buscador-grupos__component-home__mobile">
		<div class="buscador-grupos__component-home__mobile__container">
			<div class="input-destino">
				<label>{{ $t('searcher.destiny.title') }}</label>
				<div class="selector-input">
					<template v-if="dataBuscadorGruposRealizado.lugarExperiencia">
						<span >{{ titleCase(dataBuscadorGruposRealizado.lugarExperiencia.nombre) }}</span>
					</template>
					<template v-else>
						<div data-bs-toggle="modal" data-bs-target="#modalBuscadorGruposDestinos" class="subtitulo-selector">
							{{ $t('searcher.destiny.subtitle') }}
							<i class="fa-solid fa-chevron-down" />
						</div>
					</template>
					<button
						v-if="dataBuscadorGruposRealizado.lugarExperiencia"
						class="btn btn-secondary p-0 px-2"
						@click="limpiarDestinoBuscador">
						<i class="fa-solid fa-x fa-sm" />
					</button>
				</div>
			</div>

			<div class="input-fechas">
				<label>{{ $t('searcher.date.title') }}</label>
				<div class="selector-input">
					<template v-if="dataBuscadorGruposRealizado.date">
						{{ moment(dataBuscadorGruposRealizado.date).format('MMMM') }} -
						{{ dataBuscadorGruposRealizado.date.year }}
					</template>
					<template v-else>
						<div
							id="buttonModalBuscadorGruposFechas"
							type="button"
							data-bs-toggle="modal"
							data-bs-target="#modalBuscadorGruposFechas"
							class="subtitulo-selector">
							{{ $t('searcher.date.subtitle') }}
							<i class="fa-solid fa-chevron-down" />
						</div>
					</template>
					<button
						v-if="dataBuscadorGruposRealizado.date"
						class="btn btn-secondary p-0 px-2"
						@click="limpiarFechaBuscador">
						<i class="fa-solid fa-x fa-sm" />
					</button>
				</div>
			</div>

			<div class="input-grupos">
				<label :class="error ? 'text-danger' : ''">
					{{ $t('searcher.groups.title') }}
				</label>
				<div
					class="selector-input"
					:class="error ? 'text-danger' : ''"
					data-bs-toggle="modal"
					data-bs-target="#modalBuscadorGruposTipos">
					<span v-if="dataBuscadorGruposRealizado.tipoViaje">
						{{ dataBuscadorGruposRealizado.tipoViaje.descripcion }}
					</span>
					<span v-else>{{ $t('searcher.groups.subtitle') }}</span>
					<i class="fa-solid fa-chevron-down" />
				</div>
			</div>

			<div class="input-group d-flex flex-column align-items-center">
				<button class="btn btn-primary w-50" @click="doBusquedaGrupos">
					{{ $t('general.buscar') }}
				</button>
			</div>
		</div>
	</div>

	<div
		id="modalBuscadorGruposDestinos"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="modalBuscadorGruposDestinos"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 id="modalBuscadorGruposDestinosTitle" class="modal-title text-primary">
						{{ $t('searcher.destiny.title') }}
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
				</div>
				<div class="modal-body">
					<div class="column justify-content-around py-2">
						<div v-for="(area, index) in dataFiltroViajes.destino" :key="index" class="col-12">
							<div class="fw-bold text-primary">
								{{ area.nombre }}
							</div>
							<div v-for="lugarExperiencia in area.lugaresExperiencia" :key="lugarExperiencia.nombre" role="button">
								<div
									:id="lugarExperiencia.id"
									class="buscador-subtitulo"
									:value="lugarExperiencia.nombre"
									data-bs-dismiss="modal"
									@click="sendDestinoBuscador(lugarExperiencia)">
									{{ titleCase(lugarExperiencia.nombre) }}
								</div>
							</div>
							<br />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		id="modalBuscadorGruposFechas"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="modalBuscadorGruposFechas"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 id="modalBuscadorGruposFechasTitle" class="modal-title text-primary">
						{{ $t('searcher.date.title') }}
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
				</div>
				<div class="modal-body d-flex justify-content-center">
					<Datepicker
						inline
						month-picker
						auto-apply
						:min-date="new Date(2022, 11, 1)"
						class="text-primary"
						@update:model-value="sendFechaBuscador" />
				</div>
			</div>
		</div>
	</div>

	<div
		id="modalBuscadorGruposTipos"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="modalBuscadorGruposTipos"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 id="modalBuscadorGruposTiposTitle" class="modal-title text-primary">
						{{ $t('searcher.groups.title') }}
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
				</div>
				<div class="modal-body d-flex flex-column justify-content-center">
					<div
						v-for="(grupo, index) in filtroViajes.tiposGrupo"
						:key="index"
						role="button"
						class="row align-items-center py-2"
						data-bs-dismiss="modal"
						@click="sendGruposBuscador(grupo)">
						<div class="col-8">
							<div class="buscador-titulo fw-bold text-primary">
								<p class="mb-0">
									{{ grupo.descripcion }}
								</p>
								<p class="text-muted fw-normal mb-0" style="font-size: 0.8rem">
									{{ selectorDescripcionGrupos(grupo.nombre) }}
								</p>
							</div>
						</div>
						<div class="col-4 seccion-boton-tipo">
							<div
								:class="
									tipoGrupoSeleccionado(grupo.descripcion)
										? 'circulo-seleccionado d-flex align-items-center justify-content-center'
										: 'circulo-seleccion'
								">
								<i v-if="tipoGrupoSeleccionado(grupo.descripcion)" class="fa-solid fa-check text-primary" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref } from 'vue';
	import { titleCase } from '@/helpers/filters';
	import Datepicker from '@vuepic/vue-datepicker';
	import { selectorDescripcionGrupos } from '@/helpers/viajesGrupos';
	import moment from 'moment';

	const props = defineProps({
		informacionBusquedaGrupos: {
			type: Object,
			required: true,
		},
		filtroViajes: {
			type: Object,
			required: true,
		},
	});

	const error = ref(false);

	const emits = defineEmits([
		'limpiarDestinoBuscador',
		'setearDestinoBuscador',
		'limpiarFechaBuscador',
		'setearFechaBuscador',
		'setearGruposBuscador',
		'doBusquedaViajesGrupos',
	]);

	const dataBuscadorGruposRealizado = computed(() => props.informacionBusquedaGrupos);
	const dataFiltroViajes = computed(() => props.filtroViajes);

	function tipoGrupoSeleccionado(tipoGrupoDescripcion) {
		return dataBuscadorGruposRealizado.value.tipoViaje?.descripcion == tipoGrupoDescripcion;
	}

	function limpiarDestinoBuscador() {
		emits('limpiarDestinoBuscador');
	}

	function limpiarFechaBuscador() {
		emits('limpiarFechaBuscador');
	}

	function sendDestinoBuscador(lugarExperiencia) {
		emits('setearDestinoBuscador', lugarExperiencia);
	}

	function sendFechaBuscador(fecha) {
		document.getElementById('buttonModalBuscadorGruposFechas').click();
		emits('setearFechaBuscador', fecha);
	}

	function sendGruposBuscador(grupo) {
		emits('setearGruposBuscador', grupo);
	}

	function doBusquedaGrupos() {
		if (!dataBuscadorGruposRealizado.value.tipoViaje) {
			error.value = true;
		} else {
			error.value = false;
			emits('doBusquedaViajesGrupos');
		}
	}
</script>
