<template>
	<template v-if="storeGlobal.isSeaAndNightUrl">
		<div>
			<BannerTopVideoPage is-home class="position-relative" />
			<div>
				<CardsViajesSelector class="mt-5 mb-3" />
				<CarouselMejoresAgrupaciones :agrupaciones="agrupaciones" />

				<PorqueReservar2 />
			</div>
		</div>
	</template>
	<template v-else>
		<div>
			<BannerTopPage>
				<div class="home-view banner-home row container text-white"></div>
			</BannerTopPage>

			<div>
				<AgrupacionesExperienciasCollection :agrupaciones="agrupaciones" />

				<template v-if="checkDomain(['skiandnight'])">
					<BuscadorSectionHome />
					<PorqueReservar />
				</template>

			</div>
		</div>
	</template>
</template>

<script setup>
	import { onBeforeMount, onMounted, ref } from 'vue';
	import { useRoute } from 'vue-router';
	import AgrupacionesExperienciasCollection from '@/components/home/AgrupacionesExperienciasCollection.vue';
	import BuscadorSectionHome from '@/components/home/BuscadorSectionHome.vue';
	import { useAgrupacionesStore } from '@/store_pinia/agrupaciones';
	import { appGlobalStore } from '@/store_pinia/app';
	import BannerTopPage from '@components/BannerTopPage.vue';
	import BannerTopVideoPage from '@components/BannerTopVideoPage.vue';
	import CarouselMejoresAgrupaciones from '@/components/Carousel/CarouselMejoresAgrupaciones.vue';
	import CardsViajesSelector from '@components/home/CardsViajesSelector.vue';
	import PorqueReservar from '@components/misc/PorqueReservar.vue';
	import PorqueReservar2 from '@components/misc/PorqueReservar2.vue';
	import { checkDomain } from '@/helpers/domainUtils';
	import { saveComercialId } from '@/helpers/viajesGrupos';

	const route = useRoute();
	const storeGlobal = appGlobalStore();
	const agrupacionesStore = useAgrupacionesStore();
	const agrupaciones = ref([]);

	onBeforeMount(async () => {
		agrupaciones.value = await agrupacionesStore.fetchAgrupaciones();
	});

	onMounted(() => {
		saveComercialId(route.query.comercialId);
	});
</script>

<style>
	.home-view .banner-home {
		max-width: 800px;
		margin: auto;
		padding-top: 56px;
	}

	.home-view .banner-home__title {
		margin-bottom: 1rem;
		font-size: 40px !important;
	}
</style>
