<template>
	<div v-if="showSelector" class="col my-2">
		<div class="title-card d-flex p-3 justify-content-between align-items-center rounded-2">
			<div class="d-flex align-items-center">
				<img
					v-if="agrupacionServicios.icono"
					height="36"
					:src="agrupacionServicios.icono"
					:alt="agrupacionServicios.nombre"
					class="me-2">
				<i v-else :class="agrupacionServiciosMetaData.icono" class="me-2 text-primary" />
				<span class="text-primary translate">{{ agrupacionServicios.nombre }}</span>
				<InfoIcon
					v-if="hasServiciosNominales"
					class="custom-svgInfo"
					size="12px"
					:message-info="t('servicios.tooltipMessageIsNominal')" />
			</div>
			<div class="d-flex">
				<span v-if="precioTotal.value !== 0" class="text-primary px-2 extrasServicePrice">
					<span v-if="precioTotal > 0">+</span>{{ precioTotal }}{{ agenciaStore.getCurrency }}
				</span>
			</div>
		</div>
		<div class="body-card">
			<div class="row d-flex justify-content-center mt-3">
				<template v-if="agrupacionServicios.arquetipo === 'subfiltro'">
					<ReservaServiciosSubfiltrosSelector
						:idx-reserva="idxReserva"
						:agrupacion-servicios="agrupacionServicios"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
				<template v-else>
					<ReservaServiciosIndividualSelector
						:idx-reserva="idxReserva"
						:agrupacion-servicios="agrupacionServicios"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
<!--				<template v-else-if="agrupacionServicios.arquetipo === 'subtipo'">-->
<!--					<ReservaServiciosSubtipoSelector-->
<!--						:agrupacion-servicios="agrupacionServicios"-->
<!--						:idx-reserva="idxReserva"-->
<!--						@servicio-seleccionado="handlerServicioSeleccionado" />-->
<!--				</template>-->
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue';
	import ReservaServiciosSubfiltrosSelector from '@/modules/viajesGrupos/components/ReservaServiciosSubfiltrosSelector.vue';
	import ReservaServiciosIndividualSelector from '@/modules/viajesGrupos/components/ReservaServiciosIndividualSelector.vue';
	// import ReservaServiciosSubtipoSelector from '@/modules/viajesGrupos/components/ReservaServiciosSubtipoSelector.vue';
	import { getCategoriasServiciosMetaData } from '@/helpers/serviciosUtils';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import currency from 'currency.js';
	import { calculaPrecioForServicioWithCantidad } from '@/helpers/viajesGrupos';
	import InfoIcon from '@/components/Icons/InfoIcon.vue';
	import { useI18n } from 'vue-i18n';

	const props = defineProps({
		agrupacionServicios: {
			type: Object, // VGAgrupacionServiciosDTO
			default: null,
		},
		idxReserva: {
			type: Number,
			default: null,
		},
	});

	const { t } = useI18n();
	const agenciaStore = agenciaStoreModule();
	const servicioWithCantidadSeleccionado = ref(null);
	const agrupacionServiciosMetaData = getCategoriasServiciosMetaData().find(
		({ selector }) => selector === props.agrupacionServicios.tipo
	);

	const showSelector = computed(
		() => (servicioWithCantidadSeleccionado.value && !servicioWithCantidadSeleccionado.value.servicio?.nulo) ||
			props.agrupacionServicios.servicios.some(s => !s.nulo && s.activo)
	);

	const precioTotal = computed(() => {
		let serviciosWithCantidad = servicioWithCantidadSeleccionado.value || [];
		if (!Array.isArray(serviciosWithCantidad)) {
			serviciosWithCantidad = [serviciosWithCantidad];
		}
		return serviciosWithCantidad.reduce(
			(accum, swc) => accum.add(calculaPrecioForServicioWithCantidad(swc, false)),
			currency(0)
		);
	});

	const hasServiciosNominales = computed(() => {
		return props.agrupacionServicios.servicios.some(servicio => servicio.nominal && !servicio.nulo);
	});

	function handlerServicioSeleccionado(data) {
		servicioWithCantidadSeleccionado.value = data;
	}
</script>

<style lang="css">
	.title-card {
		background-color: rgba(249, 247, 248, 1);

		border-radius: var(--bs-border-radius-lg);
	}

	.extrasServicePrice {
		font-size: 1.1rem;
		font-weight: 500;
	}

	.custom-svgInfo {
		margin-left: 0.3rem;
	}

	.custom-svgInfo path {
		fill: #636c87;
	}
</style>
