import { defineStore } from 'pinia';
import AgrupacionesApiService from '../services/api/skiandnight/AgrupacionesApiService';

export const useAgrupacionesStore = defineStore('agrupacionesStore', {
	state: () => ({
		agrupaciones: null,
		agrupacion: null,
	}),
	getters: {
		getAgrupaciones(state) {
			return state.agrupaciones;
		},
		getAgrupacion(state) {
			return state.agrupacion;
		}
	},
	actions: {
		async fetchAgrupaciones() {
			if (this.agrupaciones == null) {
				this.agrupaciones = await AgrupacionesApiService.getResumenAgrupaciones();
			}
			return this.agrupaciones;
		},
		async fetchAgrupacion(uri, codigo = null) {
			if (this.agrupacion === null || this.agrupacion.uri !== uri) {
				this.agrupacion = await AgrupacionesApiService.getAgrupacion(uri, codigo);
			}
			return this.agrupacion;
		},
	},
});
