import SkiAndNightApiService from './BaseSkiAndNightApiService';

const USUARIO_API_VERSION = 'v1';
const USUARIO_API_BASEPATH_URL = '/agrupaciones/' + USUARIO_API_VERSION;

class AgrupacionesApiService {

	async getResumenAgrupaciones(titulo) {
		const params = new URLSearchParams();
		if (titulo) {
			params.append('titulo', titulo);
		}
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL, params);
	}

	async getAgrupacion(uri, codigo) {
		const params = new URLSearchParams();
		if (codigo) {
			params.append('codigo', codigo);
		}
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/${uri}`), params);
	}

}

export default new AgrupacionesApiService();
