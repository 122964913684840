export default {
  "actions": {
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare"])}
  },
  "header": {
    "horarioAtencion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lun-Gio dalle 9:00 alle 18:30, Ven dalle 9:00 alle 14:00"])},
    "ski": {
      "groups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nostre esperienze"])},
        "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universitari"])},
        "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scuole"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratori"])},
        "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famiglie"])},
        "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi Privati"])}
      },
      "destinations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinazioni"])}
      },
      "stations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stazioni"])}
      }
    },
    "night": {
      "festivals": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festival"])}
      },
      "apres": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après ski"])}
      }
    },
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi Siamo"])},
      "whoWeAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi Siamo"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavora con Noi"])}
    },
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])}
    },
    "login": {
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto!"])},
      "welcomePlanner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benvenuto a ", _interpolate(_named("planner")), "!"])},
      "continuarGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con Google"])},
      "tusDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi dati"])},
      "restablecerPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta password"])},
      "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrare"])}
    }
  },
  "searcher": {
    "organized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nostre esperienze"])},
    "personalized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienze su misura"])},
    "destiny": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinazione"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove volete andare?"])}
    },
    "date": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli quando potete andare"])}
    },
    "datesAccomodation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Alloggio"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli quando vuoi andare"])}
    },
    "datesForfait": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Skipàss"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date del Skipass"])}
    },
    "rooms": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanze"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli le stanze"])}
    },
    "groups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di Gruppo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da dove venite?"])}
    },
    "resumenReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo Prenotazione"])}
  },
  "filters": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
    "restablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina"])},
    "alojamiento": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai esaurito l'alloggio"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila i seguenti dati e, se ci sono stanze disponibili, ti avviseremo"])}
    }
  },
  "home": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
    "banner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIVI L'ESPERIENZA COMPLETA"])},
      "titleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LE NOSTRE ESPERIENZE"])},
      "titlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRENOTA I TUOI VIAGGI POKER PREFLOP"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti alle nostre esperienze con i tuoi amici e scopri una comunità di viaggiatori come te. Seleziona una destinazione, le date in cui vorresti viaggiare e la tua città di partenza. Cosa aspetti a unirti alle migliori esperienze sulla neve?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consigliatore di esperienze"])},
      "eligeFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli quando viaggiare"])},
      "experienciasAnyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienze 24/25"])},
      "experiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienze"])},
      "eligeTuEvento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tuo evento"])},
      "experienciasAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienze su misura"])}
    },
    "festivales": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LE NOSTRE ESPERIENZE"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggia con i tuoi amici verso le nostre destinazioni preferite dove potrai goderti i nostri due eventi principali: AWS Formi e WSF Pas de la Casa."])},
      "titleDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nostre esperienze"])},
      "subtitleDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri esperienze uniche pensate per connettere le comunità attraverso viaggi ed eventi"])},
      "titleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tuo evento"])},
      "subtitleBsspain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salsa, bachata o mix in qualsiasi data o città"])},
      "titlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimi tornei"])},
      "subtitlePr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggia con i tuoi migliori amici verso i migliori festival di poker del paese e alloga' sempre vicino al casinò"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])}
    },
    "buscadorSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea la tua esperienza su misura"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nostri viaggi di gruppo non fanno per te? Usa il nostro motore di ricerca per configurare il viaggio completamente su misura."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al motore di ricerca"])}
    },
    "porqueReservar": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perché prenotare con Ski&Night?"])},
      "skiEnjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIARE, GODERE"])},
      "sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DORMIRE, "])},
      "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIPETI"])},
      "texto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizza il tuo viaggio sulla neve verso qualsiasi destinazione in Europa."])},
      "texto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" sono le destinazioni perfette per godersi la neve e il miglior divertimento notturno."])},
      "texto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con Ski&Night ti offriamo un'esperienza unica nelle migliori destinazioni, in un vero paradiso naturale. Ti offriamo"])},
      "texto4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" e l'accesso ai festival così che tu possa solo preoccuparti di divertirti."])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alloggio, lezioni"])},
      "material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noleggio attrezzatura"])}
    },
    "packs": {
      "school": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le scuole"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hai tra i 5 e i 17 anni e vuoi sfruttare al massimo i giorni di sci, questo è il tuo viaggio. Migliora la tua tecnica e goditi la natura, noi ti forniamo il trasporto, l'alloggio e gli istruttori."])}
      },
      "family": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le famiglie"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti offriamo alloggi comodi per viaggiare sulla neve con la tua famiglia. Inoltre, puoi prenotare lezioni di sci per i bambini e molte altre attività adatte alle tue esigenze."])}
      },
      "university": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per universitari"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo è il tuo viaggio se vuoi incontrare persone della tua età ai migliori festival e après-ski. Ti senti pronto per vivere un'esperienza indimenticabile sulla neve?"])}
      },
      "company": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per lavoratori"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggiare con i tuoi colleghi è molto facile. Se hai più di 25 anni, unisciti ai nostri viaggi organizzati per incontrare persone come te, in cui lo sci e il divertimento notturno ti sorprenderanno."])}
      },
      "premium": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi Premium"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota un viaggio esclusivo nelle migliori strutture sulla neve, arricchendo le giornate di sci con esperienze uniche sulla neve: gastronomia, relax, sport estremi o esperienze culturali."])}
      }
    },
    "festival": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter Snow Festival, il nostro festival"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ski&Night abbiamo creato l'esperienza Winter Snow Festival, il nostro festival sulla neve in cui puoi goderti i migliori DJ e musicisti del momento in mezzo alle montagne."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quest'anno festeggiamo la 4ª edizione del WSF, un'esperienza unica sulla neve che puoi goderti in Andorra insieme a oltre 5.000 persone come te."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta i nostri festival"])}
    },
    "apres": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I migliori après ski"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo specialisti nell'organizzazione delle migliori feste sulla neve. Dopo una giornata sulla neve, puoi goderti della buona musica dei nostri DJ in un ambiente incomparabile. Ecco una selezione degli après ski più esclusivi:"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra tutti"])}
    },
    "experiencias": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le migliori esperienze"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutto"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte"])}
    },
    "offers": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le migliori offerte"])}
    },
    "stations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le migliori stazioni sciistiche"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizziamo le migliori gite sulla neve a Formigal, Grandvalira e Sierra Nevada, tra altre stazioni. Dai un'occhiata alle stazioni in cui organizziamo viaggi e scegli quella che fa per te."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra Tutti"])}
    },
    "form": {
      "realizarCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua il check-in"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
      "tamañoMaximo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dimensione massima del file da caricare è "])},
      "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
      "personalInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazione personale"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome di battesimo"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
        "expedicionDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di emissione del passaporto"])},
        "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
        "welcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bentornati"])},
        "emailLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua email per accedere o registrarti"])},
        "dataRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci i tuoi dati per registrarti"])},
        "emailOtherLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi con un altro account"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "confirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma email"])},
        "alergias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergie"])},
        "passwordRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "ocultar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi"])},
        "mostrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra"])},
        "password8CharReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 8 caratteri"])},
        "password1MayusReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 1 lettera maiuscola"])},
        "password1NumReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno 1 numero"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password attuale"])},
        "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Password"])},
        "confirmnewpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la nuova password"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
        "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
        "provincia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
        "codPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Postale"])},
        "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
        "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genere"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschio"])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femmina"])},
        "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazionalità"])},
        "diaHoraLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorno e Orario di Arrivo"])},
        "horaSalida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di partenza"])},
        "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di nascita"])},
        "diaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorno di arrivo"])},
        "horaLlegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di arrivo"])},
        "anversoReversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fronte e retro della carta d'identità caricata"])},
        "alquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noleggio attrezzatura"])},
        "descripcionAlquilerMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dati sono indicativi; in negozio puoi provare a cambiarlo con quello che più ti si addice"])},
        "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm)"])},
        "ingresaAltura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Height"])},
        "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza (kg)"])},
        "ingresaPeso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l'altezza"])},
        "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misura del piede (EU)"])},
        "ingresaTallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la dimensione del piede"])},
        "emailComercialesActivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email commerciali attive"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi raccontarci qualcosa di più su di te?"])},
        "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivere qui"])},
        "ADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte anteriore del passaporto"])},
        "msjADni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve essere un PDF in formato PNG, JPG o GIF"])},
        "selectedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Documento"])},
        "selecciona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
        "RDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retro del passaporto"])},
        "anversoReversoDnis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fronte e retro del passaporto"])},
        "howDid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come hai saputo di noi?"])},
        "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se vuoi puoi allegare il tuo CV"])},
        "errorPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error validating password, check credentials."])},
        "noTienesCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante la convalida della password, controlla le credenziali"])},
        "aceptoMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto l'invio di aggiornamenti e comunicazioni commerciali."])},
        "noRecibirMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non voglio ricevere notizie e comunicazioni commerciali."])},
        "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])},
        "contraseniaLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua password per accedere"])},
        "errorRegPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve soddisfare tutti i requisiti di sicurezza."])},
        "errorEmailFormal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email non ha un formato corretto."])},
        "logeate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto"])},
        "msjcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per una password più sicura, ti consigliamo di utilizzare una combinazione di lettere maiuscole e minuscole, numeri e caratteri speciali come *, %, !."])},
        "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
        "iniciarSesionRegistrarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi o registrati per prenotare"])},
        "registradoCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrato con successo"])}
      },
      "tripInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche del Viaggio"])},
        "whereFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da dove stai viaggiando?"])},
        "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quante persone siete?"])},
        "whoTravel": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con chi stai viaggiando?"])},
          "friends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amici"])},
          "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famiglia"])},
          "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coppia"])},
          "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colleghi di lavoro"])}
        },
        "places": {
          "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
          "formigal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formigal"])},
          "sierraNevada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Nevada"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro luogo"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna preferenza"])}
        },
        "fields": {
          "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenti"])},
          "otherStation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra Stazione"])},
          "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri"])},
          "writeHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi qui"])},
          "otherInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro Interesse"])},
          "otherCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro Compagno"])}
        },
        "interests": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa ti interessa di più?"])},
          "cousine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomia"])},
          "party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festa e atmosfera"])},
          "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax e spa"])},
          "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività sulla neve"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri interessi"])}
        },
        "distance": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza dalle piste"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vicino alle piste"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna preferenza"])}
        },
        "whenTravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date del viaggio"])},
        "howManyDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quanti giorni vuoi sciare?"])},
        "destiny": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove vuoi andare?"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna preferenza, consigliami!"])}
        },
        "accommodation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove preferiresti soggiornare?"])},
          "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
          "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartamento"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ho preferenze"])}
        },
        "tracks": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza dalle piste?"])},
          "near": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voglio stare vicino alle piste"])},
          "noPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non mi dispiace spostarmi"])}
        }
      },
      "Thanks": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti contatteremo entro 48 ore!"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per le informazioni. Presto riceverai notizie."])}
      },
      "policy": {
        "readAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho letto e accetto la"])},
        "readAccepts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho letto e accetto le"])},
        "readAcceptsLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho letto e accetto i"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e i"])},
        "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termini e condizioni"])},
        "freePlacesPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica sui posti liberi"])},
        "policyCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica di Cancellazione"])},
        "policyAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica di Alloggio a Piena Occupazione"])},
        "cancelReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione della Prenotazione"])},
        "titleCondicionesCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni e spese di cancellazione"])},
        "msgCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le condizioni di prenotazione per il viaggio sono le seguenti:"])},
        "descriptionCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il cliente annulla il viaggio, dopo la formalizzazione del deposito richiesto per ritenere la prenotazione RIMBORSABILE come definitiva, dovrà pagare le seguenti somme:"])},
        "descriptionCondicionesNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il cliente annulla il viaggio, dopo la formalizzazione del deposito richiesto per ritenere la prenotazione NON RIMBORSABILE come definitiva, dovrà pagare le seguenti somme:"])},
        "detail1Condiciones": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il ", _interpolate(_named("porcentaje")), "% se avviene tra ", _interpolate(_named("fechaInicio")), " e prima del ", _interpolate(_named("fechaFin"))])},
        "detail2Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il 100% se è entro 15 giorni naturali prima della partenza."])},
        "detail3Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prenotazione del viaggio non verrà rimborsata in nessun caso."])},
        "detail4Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi anche effettuare un cambio di titolare della prenotazione in modo che un compagno ti sostituisca, questo cambio potrebbe comportare un costo."])},
        "detail5Condiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I costi di gestione associati alle modifiche alla prenotazione o al mancato rispetto dei termini di pagamento non saranno rimborsati in nessun caso."])},
        "detail1Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dal ", _interpolate(_named("fechaInicio")), " al ", _interpolate(_named("fechaFin")), " "])},
        "detail2Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I supplementi e le spese associati a modifiche della prenotazione o dovuti al mancato rispetto dei termini e delle tariffe di pagamento non saranno in nessun caso rimborsabili."])},
        "detail3Condiciones1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puoi modificare gratuitamente la tua prenotazione fino al giorno ", _interpolate(_named("fecha")), " nella sezione Le mie prenotazioni del tuo profilo."])},
        "detail4Condiciones1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il cliente rene alla prenotazione dopo la formalizzazione del deposito richiesto per ritenere la prenotazione come NON RIMBORSABILE, dovrà pagare le seguenti somme:"])}
      }
    }
  },
  "sponsors": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsor e collaboratori"])}
  },
  "footer": {
    "us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi Siamo"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
      "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavora con Noi"])},
      "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande frequenti"])}
    },
    "saleGroups": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte per Gruppi"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le scuole"])},
      "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le famiglie"])},
      "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per universitari"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi per le aziende"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi premium"])}
    },
    "saleSki": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte per lo Sci"])},
      "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci Ponte di Dicembre"])},
      "christmas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci Natale"])},
      "newYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci Capodanno"])},
      "holyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sci Settimana Santa"])},
      "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre offerte"])}
    },
    "terminosCondiciones": {
      "bono": {
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni Legali di Vendita dei Servizi di TRAVEL&NIGHT S.L. - Partita IVA B87814125 L'acquisto di questo biglietto comporta l'accettazione delle condizioni legali, della politica sulla privacy e dei cookie, che puoi consultare sul sito di acquisto. Questo buono è revocabile in qualsiasi momento dall'organizzatore offrendo il rimborso dell'importo pagato. L'organizzazione stabilisce le proprie condizioni legali per l'accesso e la vendita dei servizi, che dovresti consultare sui loro media o richiedere esplicitamente a loro. Per qualsiasi reclamo relativo all'organizzazione dell'evento o dell'esperienza, il titolare di questo buono deve rivolgersi all'organizzatore. Non sono ammessi cambi o rimborsi, eccetto quelli autorizzati dall'organizzatore. L'organizzazione si riserva il diritto di ammissione e può negare o espellere il godimento di uno qualsiasi dei servizi che fanno parte dell'esperienza. TRAVEL&NIGHT non si assume alcuna responsabilità per problemi derivanti dalla contraffazione, duplicazione o vendita illegale di questo biglietto. In caso di rimborsi, l'organizzatore si riserva il diritto di non restituire le spese di gestione poiché queste sono state correttamente effettuate dalla piattaforma."])}
      }
    },
    "copyright": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juan Hurtado de Mendoza, 17 posterior, 1º Dcha, 28036 - Madrid, Spagna"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note Legali"])},
      "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica sui Cookie"])},
      "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni Generali"])}
    },
    "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguici!"])}
  },
  "us": {
    "whoWeAre": {
      "banner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIGUARDO A NOI"])},
        "text": {
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo una piattaforma digitale che sta rivoluzionando il settore dello sci, progettando esperienze sulla neve, sia individuali che di gruppo. Fin dalla nostra origine, abbiamo portato più di 30.000 viaggiatori in tutte le stazioni della Spagna, Andorra e Francia."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nostra specialità è collegare i viaggiatori e creare comunità attraverso il divertimento e lo sport sulla neve. Abbiamo i migliori après-ski e un festival proprio sulla neve, il Winter Snow Festival in Andorra. Inoltre, offriamo viaggi su misura per famiglie, aziende, scuole e gourmet, adattandoci alle esigenze e ai gusti dei nostri viaggiatori, offrendo loro la migliore offerta di relax, ristorazione e divertimento. "])}
        }
      },
      "team": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEAM DI SKI&NIGHT"])}
      },
      "values": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALORI DI SKI&NIGHT"])},
        "enviroment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente giovane e vicino"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ski&Night siamo un'azienda giovane, formata da un team vicino e flessibile, il che ci permette di conoscere molto bene il prodotto che vendiamo."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il buon umore si trasmette quando si creano esperienze sulla neve e nella capacità di adattarci alle esigenze dei nostri viaggiatori, sia per universitari che per scuole, aziende, gourmet e famiglie. "])}
        },
        "honesty": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onestà e trasparenza"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'onestà è una delle chiavi della nostra azienda, ed è la nostra massima quando si tratta di agire sia con i nostri viaggiatori che con i nostri fornitori."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin dalla nostra origine, più del 25.000 viaggiatori hanno fiducia in noi e un alto tasso di fedeltà che raggiunge l'83,8%."])}
        },
        "compromise": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impegno e senso di appartenenza"])},
          "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il marchio Ski&Night è diventato uno stile di leadership e impegno, una comunità che è cresciuta dalla sua origine per diventare un marchio leader nel settore della neve."])},
          "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un impegno che si estende non solo nella relazione con i viaggiatori, ma anche con i fornitori, le stazioni e l'ambiente naturale in cui svolgiamo la nostra attività. "])}
        },
        "figures": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRANDI CIFRE GLOBALI"])},
          "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNI DI ESPERIENZA"])},
          "travelers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIAGGIATORI"])},
          "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIAGGI DI GRUPPO"])},
          "collaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COLLABORAZIONI"])}
        },
        "season": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIEPILOGO STAGIONE 2021/22"])}
      }
    }
  },
  "porqueReservar": [
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMODITÀ DI PAGAMENTO"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decidi se preferisci pagare l'intero viaggio o solo la tua parte e consulta lo stato nel tuo profilo."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASSISTENZA PERSONALIZZATA"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nostro personale di assistenza clienti e le guide in loco risolveranno tutti i tuoi dubbi."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLESSIBILITÀ NEI TUOI VIAGGI"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona gli extra e i servizi di cui hai bisogno e personalizza al massimo il tuo viaggio."])}
    },
    {
      "icono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIVERTIMENTO SU MISURA"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goditi un'ampia offerta di relax, attività sulla neve, gastronomia e divertimento notturno."])}
    }
  ],
  "viajesCatalogo": {
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viaggi di gruppo"])},
    "placeholderSelectMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un mese"])},
    "tituloFiltroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Località"])},
    "tituloFiltroLugarExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo dell'esperienza"])},
    "tituloFiltroPrecios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi"])},
    "tituloFiltroAgrupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
    "tituloFiltroCategorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
    "filtroPoblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli le località in cui vorresti andare"])},
    "filtroPrecios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scegli l'intervallo di prezzi (", _interpolate(_named("currency")), ") in cui vuoi cercare"])},
    "filtrosFestivales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli i festival a cui vorresti partecipare"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a uno di questi viaggi di gruppo e goditi l'esperienza con i tuoi amici. Puoi prenotare una nuova camera o unirti a una esistente."])},
    "descriptionPremium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota uno dei nostri viaggi esclusivi sulla neve con esperienze relax, gourmet, culturali o estreme. Puoi anche contattarci direttamente a questo numero di telefono <a href='https://wa.me/+34910054547' target='_blank'><span class='mx-2 text-primary'> +34 910054670 </span></a> e progetteremo un viaggio completamente adattato alle tue esigenze."])}
  },
  "seleccionAlojamientos": {
    "1availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio disponibile"])},
    "availableTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggi disponibili"])},
    "descriptionTrips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per la tua esperienza di gruppo, abbiamo questi alloggi disponibili"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi posizioni"])},
    "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alloggi"])},
    "1places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alloggio"])},
    "ordenarPor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])},
    "ordenarMenorMayor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina dal prezzo più basso al più alto"])},
    "ordenarMayorMenor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina dal prezzo più alto al più basso"])}
  },
  "mejoresOfertas": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le migliori offerte"])}
  },
  "porqueReservaTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perché prenotare con Ski and Night?"])},
  "porqueReservaTitulo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perché prenotare con Sea and Night?"])},
  "habitacionBuscador": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiutaci a trovare il miglior prezzo, organizza le tue camere"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il numero di camere/appartamenti"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera"])},
    "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulti"])},
    "ninos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambini"])},
    "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambino"])},
    "edadNino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Età dei bambini"])}
  },
  "viajesGrupos": {
    "todosPublicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i pubblici"])},
    "mas25anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oltre 25 anni"])},
    "de18a25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 18 a 25 anni"])},
    "de0a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 0 a 17 anni"])},
    "msjPlazasLibres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posti liberi nella stanza, nel caso in cui rimangano liberi verrà addebitato un supplemento di"])},
    "msjRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per ogni posto libero da ripartire tra i membri della stanza come 'Resto dell'alloggio'"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posto libero"])},
    "invitarCorreo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi invitare via email cliccando sul pulsante."])},
    "llenarInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila le informazioni su Raccontaci qualcosa di te."])},
    "faltaPoliticasPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le politiche sulla privacy e la politica di alloggio a piena occupazione devono essere confermate."])},
    "faltaPoliticasCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le politiche di cancellazione devono essere confermate."])},
    "faltaRespuestasFormulario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le domande del modulo devono essere risposte."])},
    "infoSeleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una volta terminata la prenotazione, inviate il link che vi forniremo al resto delle persone in modo che possano partecipare alla vostra esperienza."])}
  },
  "reserva": {
    "misReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie prenotazioni"])},
    "reactivarParaRecuperla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca sul pulsante 'Riattiva' per recuperarla"])},
    "proximasReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue prossime prenotazioni"])},
    "misQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei QR"])},
    "proximasNights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei prossimi biglietti"])},
    "estadoPagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato del pagamento della prenotazione"])},
    "informacionExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'esperienza"])},
    "personasHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone nella camera"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo"])},
    "tabs": {
      "personas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone nella camera"])},
      "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo"])},
      "informacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sull'esperienza"])},
      "estadoPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato del pagamento"])},
      "comunidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunicazione"])}
    },
    "comunidad": {
      "invitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invito a una comunità"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti alla comunità"])},
      "descripcionInvitacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sei stato invitato a unirti a una comunità nell'esperienza ", _interpolate(_named("experiencia")), ". Seleziona con quale delle tue prenotazioni desideri unirti."])},
      "tusReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue prenotazioni in questa esperienza"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea la tua comunità o unisciti a una già esistente e raggruppa la tua prenotazione con altre. La comunità permette all'organizzatore di sapere con chi viaggi e di tenerne conto per i trasporti, gli alloggi e gli altri servizi dell'esperienza. Le comunità sono private; per unirti a una, devi richiedere il link al suo creatore."])},
      "creacionExitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunità creata con successo"])},
      "boton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea comunità"])},
      "yaEnSupergrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartieni già a questo supergruppo"])},
      "aunSinReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora prenotazioni"])},
      "pincha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca"])},
      "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
      "paraComenzarReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per iniziare a prenotare"])},
      "miembrosComunidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri della comunità"])},
      "unidoExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai unito correttamente la prenotazione alla comunità"])},
      "seleccionaGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un gruppo per unirti"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea comunità"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della tua comunità"])},
      "pertenece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua prenotazione appartiene alla seguente comunità:"])},
      "compartirLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi il link affinché altre persone possano unirsi direttamente alla tua comunità:"])},
      "miembros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri"])},
      "miembro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membro"])},
      "plazaLibre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posti liberi"])},
      "confirmarEliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare questa comunità?"])},
      "noRecuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una volta eliminata, non potrà essere recuperata."])},
      "errorEliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante l'eliminazione della comunità"])},
      "errorCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante la creazione della comunità"])},
      "eliminacionExitosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunità eliminata con successo"])}
    },
    "mensajeCompra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando effettui un acquisto, apparirà qui"])},
    "pendientePago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di pagamento"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale prenotazione"])},
    "reservaEncuentra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa prenotazione si trova"])},
    "miViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio viaggio"])},
    "verQRs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi i QR"])},
    "reservasCancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona le prenotazioni che vuoi annullare"])},
    "mensajeCompraExp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Clicca ", _interpolate(_named("aqui")), " per vedere tutte le esperienze disponibili"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "paga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
    "totalPagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale pagato"])},
    "totalPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale rimanente"])},
    "saldoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equilibrio eccezionale"])},
    "graciasReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per la prenotazione"])},
    "noReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ancora prenotazioni"])},
    "noFiestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai prenotazioni di feste"])},
    "moreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccontaci di più su di te"])},
    "howPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli come vuoi pagare"])},
    "complementos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complementi"])},
    "suplementos": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplementi e spese"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplementi e spese"])},
      "PLAZAS_LIBRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posti liberi"])}
    },
    "tasas": {
      "externas": {
        "TASA_TURISTICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa di soggiorno"])}
      },
      "internas": {
        "TASA_GESTION_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione pagamento carta di credito"])},
        "TASA_GESTION_CAMBIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa di gestione di cambio"])}
      }
    },
    "tasa": {
      "externas": {
        "turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa turistica"])}
      },
      "gestion-plataforma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione pagamento carta di credito"])},
      "gestion-cambio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa di gestione del cambio"])}
    },
    "pagos": {
      "pago": {
        "senial": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acconto"])}
        },
        "total": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento totale dell'esperienza"])}
        },
        "viaje": {
          "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento parziale"])},
          "diferido": {
            "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento differito"])}
          }
        }
      }
    },
    "tipo": {
      "PC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensione completa"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mezza pensione"])},
      "AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera e colazione"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo camera"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo colazione"])}
    },
    "superGrupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Gruppo"])},
      "informacionTransporte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul trasporto"])},
      "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sul tuo viaggio"])},
      "fiestaContradas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste prenotate"])},
      "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea super-gruppo"])},
      "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a un super-gruppo"])},
      "msjNoGrupoamigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo gruppo non ha ancora creato un super-gruppo. Crea un super-gruppo o unisciti a uno esistente prima della data di prenotazione per viaggiare nello stesso autobus e alloggiare nelle camere più vicine possibili."])},
      "msjSuperGruposEdificio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I super-gruppi saranno considerati solo per edifici vicini a causa del punto di arrivo."])},
      "pertenceSuperGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai parte del super-gruppo"])},
      "msjPuntoLlegado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I super-gruppi saranno considerati solo per edifici vicini a causa del punto di arrivo."])}
    },
    "plazasLibres": {
      "modal": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono ancora posti liberi nella tua prenotazione!"])},
        "tituloSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["C'è ancora ", _interpolate(_named("plazasLibres")), " posto libero nella tua prenotazione!"])},
        "tituloPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ci sono ancora ", _interpolate(_named("plazasLibres")), " posti liberi nella tua prenotazione!"])},
        "bodySingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["C'è spazio per ", _interpolate(_list(0)), " persona nella tua prenotazione. Sarà aggiunto un supplemento di ", _interpolate(_list(1)), " a ogni persona confermata. Se i posti liberi verranno infine occupati, il supplemento sarà rimborsato."])},
        "bodyPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["C'è spazio per altre ", _interpolate(_list(0)), " persone nella tua prenotazione. Sarà aggiunto un supplemento di ", _interpolate(_list(1)), " a ogni persona confermata. Se i posti liberi verranno infine occupati, il supplemento sarà rimborsato."])},
        "addSuplemento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi supplemento alla prenotazione"])},
        "genteReservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mancano persone alla prenotazione"])},
        "completarPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riempire i posti"])}
      }
    },
    "grupo": {
      "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
      "msjGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggruppa la tua prenotazione con altre. I gruppi saranno presi in considerazione per i servizi dell'esperienza, ad esempio nella distribuzione dei trasporti. Per creare un gruppo, invia il link agli utenti delle altre prenotazioni."])},
      "miembrosGrupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membri del gruppo"])}
    },
    "buses": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autobus"])},
      "horaSalidaIda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di partenza (andata)"])},
      "lugarSalidaIda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di partenza (andata)"])},
      "horaSalidaVuelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di partenza (ritorno)"])},
      "lugarSalidaVuelta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di partenza (ritorno)"])}
    },
    "bono": {
      "descargarBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica voucher"])},
      "fechaIni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inizio"])},
      "fechaFin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data fine"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "informacionGeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni generali"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In"])},
      "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Out"])},
      "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regime"])},
      "impuestosIncluidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposte incluse"])},
      "incluidoPaqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluso nel pacchetto base"])},
      "servicioExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio extra"])},
      "localizador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localizzatore"])},
      "titular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email di contatto"])}
    },
    "checkIn": {
      "msg": {
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in e dati personali completati per tutte le prenotazioni."])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È obbligatorio compilare tutti i dati."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'elaborazione delle prenotazioni."])}
      },
      "altura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza"])},
      "tallaPie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di scarpe"])},
      "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "card": {
        "sinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo modulo non è completo"])},
        "sinGurdar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo modulo non è salvato"])},
        "guardado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo modulo è già stato inviato"])},
        "completar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa"])},
        "verRespuestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi risposte"])},
        "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
        "rellena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila il modulo"])},
        "rellenaLos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila i moduli"])}
      },
      "error": {
        "rellenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono moduli incompleti"])},
        "formularioIncompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il modulo non è completo"])},
        "errorEnvio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante l'invio del modulo"])}
      },
      "done": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check In di ", _interpolate(_named("nombre")), " completato"])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
      "alergias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergie"])},
      "nacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazionalità"])},
      "fechaNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
      "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di nascita"])},
      "dni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità/Passaporto"])},
      "soporteDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di supporto DNI"])},
      "fechaCaducidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza"])},
      "fechaExpedicion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di emissione"])},
      "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
      "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "codigoPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
      "sexo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesso"])},
        "hombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uomo"])},
        "mujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donna"])}
      },
      "llegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrivo"])},
      "salida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenza"])},
      "anversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fronte della carta d'identità/passaporto"])},
      "reversoDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retro della carta d'identità/passaporto"])},
      "seleccionarDocumento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona documento"])},
      "placeholders": {
        "seleccionaNacionalidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona una nazionalità"])},
        "seleccionaPais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un paese"])},
        "escribeTuRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi la tua risposta"])},
        "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gg/mm/aa"])},
        "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'agente di vendita"])}
      },
      "paises": {
        "españa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spagna"])},
        "otro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])}
      }
    },
    "informacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ulteriori informazioni sull'alloggio"])},
    "msjFechaLimite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La data limite per effettuare il pagamento o apportare modifiche ai servizi gratuitamente è prima del giorno"])},
    "msjPagoRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pagamento del tuo viaggio è stato già effettuato. Resta in attesa di ulteriori informazioni sulle feste."])},
    "msjNoPagos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deve ancora pagare ", _interpolate(_named("pendientePago")), _interpolate(_named("currency")), "."])},
    "reservasDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazione/i da"])},
    "mantenerReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantieni la prenotazione"])},
    "msjSolucionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possiamo aiutarti a trovare una soluzione alternativa se è necessario apportare modifiche alla tua prenotazione."])},
    "proximosViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossime esperienze:"])},
    "condicionesReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le condizioni di prenotazione per il viaggio sono le seguenti"])},
    "concepto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "precio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
    "recuperarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera la prenotazione"])},
    "codViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo è il codice del tuo viaggio"])},
    "mensajeThankYouSingular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avete ", _interpolate(_named("plazaslibres")), " posto libero, condividete il link per completarli o verrà aggiunto un supplemento di ", _interpolate(_named("suplemento")), " a ogni persona confermata."])},
    "mensajeThankYouPlural": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avete ", _interpolate(_named("plazaslibres")), " posti liberi, condividete il link per completarli o verrà aggiunto un supplemento di ", _interpolate(_named("suplemento")), " a ogni persona confermata."])},
    "mensajeThankYouCompleto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vostra prenotazione è composta dalle seguenti persone:"])},
    "mensajeThankYouAnular": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se non viene completata entro ", _interpolate(_named("fechaLimite")), ", verrà automaticamente annullata."])},
    "mensajeFechaLimite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Termine di pagamento: ", _interpolate(_named("fechaLimite")), "."])},
    "repartirCoste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se il numero di posti non viene riempito, verrà aggiunto un supplemento per ogni persona confermata."])},
    "mensajeExperiencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non vediamo l'ora di vederti e di vivere questa esperienza insieme."])},
    "msjAnadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi aggiungere alcuni extra se li hai dimenticati"])},
    "anadirExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi extra"])},
    "masExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri extra"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "serviciosContrados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi Contrattati"])},
    "realizarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua il pagamento"])},
    "cambioHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio di camera"])},
    "informesTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo totale"])},
    "cancelacionEntre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se la cancellazione avviene tra il"])},
    "costesAsociadosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I costi associati alla cancellazione della prenotazione sono:"])},
    "msjReservaDescartada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prenotazione è stata annullata"])},
    "msjReservaActiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attualmente hai già una prenotazione per questo viaggio, scegli un altro viaggio o consulta le tue prenotazioni."])},
    "descuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconto"])},
    "descuentoCod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di sconto"])},
    "descuentoCodIntrod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inseriscilo qui"])},
    "descuentoCodError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il codice sconto '", _interpolate(_named("codigo")), "'. Non è valido."])},
    "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacchetto Base"])},
    "suplementosGastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplementi e spese"])},
    "msjPendientePagoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai una prenotazione in sospeso per questo viaggio. Vuoi annullarla o continuarla?"])},
    "revisaDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla i dati della tua prenotazione"])},
    "pagarTotalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga l'importo totale della prenotazione"])},
    "enlaceUnirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al termine della prenotazione inviare il link che forniremo al resto delle persone affinché possano iscriversi"])},
    "recuerdaPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorda che puoi pagare l'intera prenotazione o solo una parte"])},
    "pagarAhora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga adesso"])},
    "abonarAntesDe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e paga l'importo rimanente prima"])},
    "pagarPlazos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga a rate."])},
    "reservaCancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazione annullata"])},
    "msjCheckInReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per accelerare il processo di check-in al tuo arrivo, è necessario compilare le tue informazioni. Puoi compilare i tuoi dati o quelli dei tuoi compagni di stanza/appartamento."])},
    "msjDatosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi consultare i dati di prenotazione delle persone per le quali hai effettuato il primo pagamento"])},
    "disponeCodReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai un codice di prenotazione"])},
    "msjFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcuno ha detto festa"])},
    "msjNoFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento non sono disponibili feste per nessuno dei tuoi viaggi. Sarai informato via email non appena saranno confermate, così potrai prenotare il tuo posto."])},
    "msjAmigoReservado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hai un amico che ha già prenotato e vuoi unirti alla sua camera, clicca sul pulsante sottostante"])},
    "gastos": {
      "gasto": {
        "tasa-turistica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese per tassa turistica"])},
        "gestion": {
          "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese extra di gestione"])}
        }
      },
      "FEE_PLATAFORMA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di gestione della piattaforma"])},
      "incremento": {
        "camas": {
          "vacias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesa per il resto dell'alloggio"])}
        }
      },
      "recargo": {
        "pago": {
          "tardio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese per pagamento ritardato"])}
        }
      },
      "complemento": {
        "plazas": {
          "libres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplemento per posti liberi"])}
        }
      }
    },
    "visualizacionOcupantes": {
      "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posto"])},
      "libre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libero"])}
    }
  },
  "rooming": {
    "miRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio Rooming"])},
    "resena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasciaci una recensione"])},
    "siguenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguici"])},
    "gestorRooming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestore di Rooming"])},
    "tooltipAnadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una volta cliccato qui, seleziona i membri dalla tabella a destra"])},
    "organizarDistribucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizza la Distribuzione"])},
    "tooltipMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la stanza che desideri distribuire e poi le persone che desideri mettere in quella stanza dalla tabella a sinistra"])},
    "cardHabitacion": {
      "anadirIntegrantes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ Aggiungi membri"])},
      "selectFromTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona i membri dalla tabella"])}
    },
    "estado": {
      "NUEVO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
      "EN_EDICION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In modifica"])},
      "PUBLICADO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblicato"])}
    },
    "tabla": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio"])},
      "sinRepartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non distribuiti"])},
      "repartidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribuiti"])},
      "buscarNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca nome"])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
      "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
      "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])}
    },
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "hasTerminado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai finito?"])},
    "guardarSalir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva e esci"])},
    "guardarSalirMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non hai finito di distribuire e vuoi continuare più tardi, clicca su 'Salva e esci'. Se hai finito, clicca su 'Invia', nota che una volta inviato, non potranno essere apportate modifiche."])},
    "error": {
      "publicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella pubblicazione del tuo rooming."])},
      "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nel salvare il tuo viaggio."])}
    }
  },
  "alojamiento": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio"])},
    "mejoresOfertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migliori offerte"])},
    "nuestrasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nostre esperienze"])},
    "verTodasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutto"])},
    "imagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagini"])},
    "verImagenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi immagini"])},
    "eligeDistancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la distanza dalle piste (m)"])},
    "eligeDistanciaPokerRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la distanza al casino (m)"])},
    "eligeTipo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tipo di alloggio a cui vuoi andare"])},
    "eligePrecio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la fascia di prezzo del tuo viaggio"])},
    "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galleria"])},
    "seleccion_buscador": {
      "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggi su misura"])},
      "descripcion_extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ti dammi la possibilità di scegliere i tuoi extra a tuo piacimento"])}
    },
    "ordenacion": {
      "masEconomicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno economici"])},
      "menosEconomicos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più economici"])},
      "mejorValorados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miglior valutazione"])},
      "menosValorados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peggior valutazione"])}
    },
    "habitaciones": {
      "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["camera"])},
      "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["camere"])}
    },
    "compartir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi"])},
    "tipos": {
      "galeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galleria"])},
      "apartamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartamento"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "albergue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostello"])},
      "estudio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monolocale"])},
      "no aplica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non applicabile"])}
    },
    "nombreTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "filtros": {
      "titulo": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra alloggi"])},
        "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta filtri"])}
      },
      "tipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tipo di alloggio"])},
      "precios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la fascia di prezzo del tuo viaggio"])},
      "distancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la distanza dalle piste (m)"])}
    },
    "resultados": {
      "encontradoUno": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " alloggio trovato"])},
      "encontradosVarios": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " alloggi trovati"])},
      "encontradosMuchos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più di 50 viaggi disponibili"])},
      "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato trovato con la tua ricerca, puoi fare una nuova ricerca."])},
      "sinResultadosFechas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non abbiamo alloggi disponibili per le date selezionate, ti consigliamo di iniziare una nuova ricerca. Puoi controllare la disponibilità nei nostri viaggi, e se non trovi nulla, scrivici e ti faremo delle raccomandazioni."])}
    },
    "nuestrosViajes": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nostri viaggi"])},
      "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti ai nostri viaggi con i tuoi amici e incontra una comunità di viaggiatori come te. Cosa aspetti a unirti alle migliori esperienze sulla neve?"])},
      "coincidencia": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua ricerca coincide con le date di uno di "])},
        "verCoincidencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi corrispondenza"])},
        "seguirBuscando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua la mia ricerca"])}
      },
      "queEs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa sono i nostri viaggi?"])}
    },
    "filtro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro viaggi"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra alloggi"])},
      "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta filtri"])}
    },
    "buscador": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca viaggi"])}
    },
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per persona"])},
    "valoracion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazione"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni sull'alloggio"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorni di forfait da"])},
    "estrellas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stelle"])},
    "distancia": {
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])}
    },
    "precio": {
      "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
      "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per persona"])}
    },
    "modales": {
      "nuestrosViajes": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nostri viaggi"])},
        "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti ai nostri viaggi con i tuoi amici e incontra una comunità di viaggiatori come te. Cosa aspetti a unirti alle migliori esperienze sulla neve?"])},
        "coincidencia": {
          "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua ricerca coincide con le date di uno dei nostri viaggi"])},
          "verCoincidencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi corrispondenza"])},
          "seguirBuscando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua la mia ricerca"])}
        }
      },
      "filtros": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro viaggi"])},
        "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra alloggi"])},
        "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta filtri"])},
        "tipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tipo di alloggio"])},
        "poblaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la popolazione a cui vuoi andare"])},
        "precios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la fascia di prezzo del tuo viaggio"])},
        "distancia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la distanza dalle piste (m)"])}
      },
      "buscador": {
        "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca viaggi"])}
      }
    },
    "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato trovato con la tua ricerca, puoi fare una nuova ricerca."])},
    "sinResultadosFechas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non abbiamo alloggi disponibili per le date selezionate, ti consigliamo di iniziare una nuova ricerca. Puoi controllare la disponibilità nei nostri viaggi, e se non trovi nulla, scrivici e ti faremo delle raccomandazioni."])},
    "forfait": {
      "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorni di forfait da"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])},
      "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
      "distancia": {
        "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dalla stazione sciistica"])}
      }
    },
    "lineUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line-up confermato"])},
    "compartirLanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarda il viaggio che ho trovato!"])},
    "consigueEntrada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottieni il tuo biglietto"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOSTRA DI PIÙ >"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOSTRA DI MENO >"])},
    "consigueAbono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottieni il tuo pass"])},
    "todasExperiencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutte le esperienze"])},
    "proximamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimamente..."])},
    "verFoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutte le foto"])},
    "verMapa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi mappa"])},
    "verMapaAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VEDI MAPPA ALLOGGI"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi posizioni"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dell'alloggio"])},
    "ofertas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
    "preguntasFrecuentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "horariosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari e condizioni speciali"])},
    "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
    "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
    "distancias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanze"])},
    "accesoPistas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso alle piste"])},
    "accesoPokerRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso al casinò"])},
    "puntoInteres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punti di interesse"])},
    "descripcionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione dell'alloggio"])},
    "regimenDisponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regimi disponibili e tipologie di camera"])},
    "deEstacionEsqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dalla stazione sciistica"])},
    "cancelacionGratis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione GRATUITA"])},
    "parcialmenteReembolsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parzialmente rimborsabile"])},
    "otrasOpcionesViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre opzioni di esperienza"])},
    "servicioHotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi dell'hotel"])},
    "ubicacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])},
    "fechaAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di alloggio"])},
    "filtrosAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro Alloggi"])}
  },
  "validaciones": {
    "pagador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagatore: "])},
    "validadaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERRORE: BIGLIETTO GIA' VALIDATO"])},
    "validadaExito": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIGLIETTO VALIDATO CON SUCCESSO"])},
    "validadaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validato il "])},
    "aLas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" alle "])},
    "cantidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
    "descripcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "unidades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unità"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "preguntaValidar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi convalidare il biglietto?"])},
    "validar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convalidare"])}
  },
  "general": {
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il"])},
    "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])},
    "teneis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete"])},
    "grupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
    "antesDel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prima del"])},
    "ahora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ora"])},
    "checkInOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Online"])},
    "articulosRelacionados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articoli correlati"])},
    "pagoPlazosDeshabilitado": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ora sei aggiornato con il tuo programma di pagamento, puoi effettuare il pagamento successivo a partire dal ", _interpolate(_named("fecha")), "."])},
    "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione"])},
    "verMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi di più"])},
    "verMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedere meno"])},
    "crear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
    "pagos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti"])},
    "atras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio profilo"])},
    "checkInRealizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in effettuato"])},
    "codigoCopiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice è stato copiato"])},
    "pagoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento in sospeso"])},
    "siguiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "ordenar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])},
    "anios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anni"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzione"])},
    "viaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggio"])},
    "mostrarMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra di più"])},
    "mostrarMenos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra meno"])},
    "viajeAMedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperienze su misura"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "cerrarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
    "solucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
    "agotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esaurite"])},
    "para": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per"])},
    "pagado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagato"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a partire da"])},
    "seleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione"])},
    "terminosCondiciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termini e condizioni"])},
    "proteccionDatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione dei dati personali"])},
    "porPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per persona"])},
    "masInformacionViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni su questo viaggio"])},
    "masInformacionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni sull'alloggio"])},
    "variasOcupaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varie occupazioni disponibili"])},
    "eligeAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tuo alloggio"])},
    "quieroSaberMas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voglio saperne di più"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra tutti"])},
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota alloggio"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a stanza"])},
    "servicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
    "ubicacionesAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi mappa"])},
    "verUbicaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedere le posizioni"])},
    "codigoInvalido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice non è valido."])},
    "bookingLetterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "bookingBusDeparture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenza"])},
    "experienceFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggio per"])},
    "dias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorni"])},
    "dia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorno"])},
    "noches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notti"])},
    "noche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notte"])},
    "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["del"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al"])},
    "aplicar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riassunto"])},
    "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posti"])},
    "plazaUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["posto"])},
    "cantidadDisponibles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Più di ", _interpolate(_named("cantidad")), " disponibili"])},
    "disponibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibili"])},
    "agotadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esaurite"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota"])},
    "listaEspera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista d'attesa"])},
    "listaEsperaSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta è stata aggiunta con successo alla lista d'attesa"])},
    "listaEsperaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile aggiungere alla lista d'attesa"])},
    "personas": {
      "singular": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adulto"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bambino"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persona"])}
      },
      "plural": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adulti"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bambini"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["persone"])}
      },
      "mayus": {
        "adulto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulto"])},
        "nino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambino"])},
        "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])}
      }
    },
    "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alojamiento"])},
    "gastos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese"])},
    "habitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera"])},
    "habitaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camere"])},
    "regimen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regime"])},
    "clipboard": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL copiata nel portapapele"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella copia dell'URL"])}
    },
    "esMiReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prenotazione è per me"])},
    "nombreCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzeremo il nome e il cognome dal tuo profilo"])},
    "persona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
    "menorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono minorenne"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "masInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più informazioni"])},
    "noInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna informazione disponibile"])},
    "masInformacionIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ informazioni"])},
    "borrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella"])},
    "esMenorEdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È minorenne"])},
    "fecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice non valido"])},
    "politicaPrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termini e condizioni"])},
    "errorMensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore"])},
    "pendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In sospeso"])},
    "ejecutando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In esecuzione"])}
  },
  "filtroAlojamiento": {
    "borrarFiltros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella filtri"])},
    "titulo": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra alloggio"])},
      "poblacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Località"])},
      "alojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di alloggio"])},
      "plaza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di posti"])},
      "reestablecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina filtri"])}
    }
  },
  "filtroModalidades": {
    "titulo": {
      "seleccionaGama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il livello"])},
      "seleccionaServicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il servizio"])}
    }
  },
  "selectSubTipos": {
    "titulo": {
      "seguroAnulacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione di Annullamento"])},
      "seguroViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione di Viaggio"])}
    }
  },
  "resumenServiciosBuscador": {
    "nochesAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni di alloggio"])},
    "diasForfait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni di skipass"])}
  },
  "baseMigas": {
    "seleccionAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione dell'alloggio"])},
    "datosReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazione"])},
    "confirmacionReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma prenotazione"])},
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "seleccionFestival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione del festival"])}
  },
  "botones": {
    "reservarAlojamiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota alloggio"])},
    "recibo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevuta"])},
    "documentacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentazione"])},
    "reservaPackFiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota il pacchetto festa"])},
    "unirseHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti alla stanza"])},
    "volverViajes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna ai viaggi"])},
    "volverReservas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornare alle mie prenotazioni"])},
    "actualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
    "reservar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "verTodos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutto"])},
    "verDetalles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza dettagli"])},
    "verReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza prenotazione"])},
    "bonoAdjunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il buono allegato"])},
    "cancelada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullata"])},
    "descargar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica"])},
    "guardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "volver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entra"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "recuperar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera"])},
    "copiar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia"])},
    "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "modificar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "modificarPagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica e paga"])},
    "agregarPersona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi persona"])},
    "descargarPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica PDF"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
    "darseBaja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disiscriviti"])},
    "aceptarSeleccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta selezione"])},
    "cancelarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla prenotazione"])},
    "modificarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica prenotazione"])},
    "descartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarta"])},
    "unirse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti"])},
    "reactivarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reattiva prenotazione"])},
    "recordar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorda"])},
    "pagarSenial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga il deposito"])},
    "pagar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])},
    "establecer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta"])},
    "resumen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo"])},
    "verBono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buono"])},
    "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuare"])},
    "reintentar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova"])}
  },
  "codigoDescuento": {
    "tienesCodDescuento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete un codice sconto?"])}
  },
  "cookies": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLITICA SUI COOKIE"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizziamo i cookie per migliorare i nostri servizi, analizzare e personalizzare la tua navigazione. Per continuare a navigare sul nostro sito web, devi accettare il loro utilizzo. Puoi cambiare le impostazioni o ottenere ulteriori informazioni sulla nostra"])},
    "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto tutti i cookie"])},
    "politicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica dei cookie"])},
    "gestionar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci i cookie"])},
    "extras": {
      "necesarias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessari"])},
      "analiticas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analitici"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])}
    },
    "preguntas": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COSA SONO I COOKIE?"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTORIZZAZIONE ALL'USO DEI COOKIE?"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO DI COOKIE UTILIZZATI?"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COME DISATTIVARE O ELIMINARE I COOKIE?"])}
    },
    "respuestas": {
      "1": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie sono file di testo memorizzati sul dispositivo dell'utente durante la navigazione su Internet. Contengono un numero che consente di identificare univocamente il dispositivo, anche se questo cambia posizione o indirizzo IP."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie vengono installati durante la navigazione su Internet, sia dai siti web visitati dall'utente sia da terze parti con cui il sito web interagisce. Questi permettono al sito web di conoscere l'attività dell'utente sullo stesso sito o su altri con cui è in relazione. Ad esempio: il luogo di accesso, il tempo di connessione, il dispositivo utilizzato (fisso o mobile), il sistema operativo e il browser utilizzati, le pagine più visitate, il numero di clic effettuati e dati sul comportamento dell'utente su Internet."])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie sono associati solo al browser e non forniscono di per sé dati personali. I cookie non possono danneggiare il dispositivo e sono molto utili per identificare e risolvere errori. Il sito web è accessibile anche senza l'attivazione dei cookie, tuttavia, la loro disattivazione potrebbe impedire il corretto funzionamento dello stesso."])}
      },
      "2": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In conformità all'avviso sui cookie visualizzato nel piè di pagina del sito web, l'utente o il visitatore del sito accetta esplicitamente l'uso dei cookie come descritto di seguito, a meno che non abbia modificato le impostazioni del proprio browser per rifiutarne l'uso."])}
      },
      "3": {
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Titolare del sito web utilizza cookie strettamente necessari per facilitare la corretta navigazione nel sito Web e per assicurare che i contenuti vengano caricati in modo efficace. Questi cookie sono anche cookie di sessione, cioè temporanei, e scadono ed eliminano automaticamente quando l'utente chiude il browser."])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie di Facebook Ireland Ltd, utilizzati per consentire ai visitatori di interagire con i contenuti di Facebook e generati solo per gli utenti di tali social media. Le condizioni d'uso di questi cookie e le informazioni raccolte sono regolate dalla politica sulla privacy della piattaforma di social media corrispondente."])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cookie possono essere disattivati ed eliminati seguendo le seguenti guide in base al browser utilizzato:"])},
        "bloque1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminare e gestire i cookie in Internet Explorer"])},
        "bloque2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare, abilitare e gestire i cookie in Chrome"])},
        "bloque3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare i cookie in Firefox"])},
        "bloque4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni web di Safari su iPhone, iPad e iPod touch"])}
      }
    }
  },
  "politicasCancelacion": {
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politiche di Cancellazione"])}
  },
  "titulos": {
    "itinerario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinerario"])},
    "incluye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluso"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota per quante persone vuoi"])},
    "completarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa i seguenti dati per continuare con la tua prenotazione"])},
    "motivosCancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivi di Cancellazione"])},
    "editarPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Profilo"])}
  },
  "subtitulo": {
    "seleccionServicios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al termine della prenotazione, invia il link che forniremo al resto delle persone affinché possano unirsi."])},
    "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il nome della tua camera"])},
    "comoConociste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come ci hai conosciuti?"])},
    "estudiando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai studiando?"])},
    "eligeUniversidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli un'università"])},
    "escribeRespuesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi la tua risposta"])},
    "comercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del commerciale"])},
    "trabajando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In quale azienda lavori?"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "importeTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo Totale"])},
    "importePendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo in Sospeso"])},
    "totalReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale Prenotazione"])},
    "codigoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di Prenotazione"])},
    "codigoViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Viaggio"])},
    "intoduceAqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inseriscilo qui"])},
    "codigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
    "misViajeros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Miei Viaggiatori"])},
    "miPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Mio Profilo"])},
    "mailingPublicidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblicità via Email"])},
    "subirDni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica il passaporto"])},
    "modificarcontrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Password"])},
    "datos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati"])}
  },
  "modalUnirseHabitacion": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai esaurito l'alloggio?"])},
    "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila i seguenti dati e, se una camera dovesse diventare disponibile, sarai avvisato"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se desideri unirti a una camera di un viaggio, utilizza il codice fornito dalla persona che ha effettuato la prenotazione"])}
  },
  "input": {
    "field": {
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "nombreApellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e Cognome"])},
      "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo Email"])},
      "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
      "numeroPlazas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di Posti"])},
      "observaciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osservazioni"])},
      "redesSociales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
      "comerciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerciale"])},
      "paqueteBasico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacchetto Base"])}
    },
    "placeholder": {
      "nombreHabitacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il nome della tua camera"])},
      "introduceCampo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il campo"])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati dell'Acquirente"])},
    "subtitleRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei ancora cliente? Registrati"])},
    "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la tua password"])},
    "recoverPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recupera Password"])},
    "questionLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei già un cliente? Accedi"])},
    "questionUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei già un utente"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma la Password"])},
    "establecerContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta la Password dell'Utente"])}
  },
  "viaje": {
    "IntroduceCodViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il codice del tuo viaggio"])},
    "colegios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hai tra 5 e 17 anni e vuoi approfittare al massimo dei giorni di sci, questo è il tuo viaggio. Migliora la tua tecnica e goditi la natura, noi ti forniamo il trasporto, l'alloggio e gli istruttori."])},
    "familias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti offriamo l'alloggio più comodo per viaggiare sulla neve con la tua famiglia. Inoltre, puoi prenotare lezioni di sci per i bambini e molte altre attività adatte alle tue esigenze."])},
    "trabajadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viaggiare con i tuoi colleghi è molto facile. Se hai più di 25 anni, unisciti ai nostri viaggi organizzati per incontrare persone come te, in cui lo sci e l'intrattenimento notturno ti sorprenderanno."])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo è il tuo viaggio se desideri incontrare persone della tua età nei migliori festival e après-ski. Ti senti pronto per vivere un'esperienza indimenticabile sulla neve?"])}
  },
  "errors": {
    "habitacion": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice non corrisponde a nessuna stanza"])}
    },
    "grupoReservas": {
      "pendientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono prenotazioni da confermare"])}
    },
    "experiencia": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'esperienza non ammette nuove prenotazioni"])}
    },
    "reserva": {
      "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno dei viaggiatori ha già prenotato per questa esperienza"])}
    },
    "servicios": {
      "cuposInsuficientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcuni dei servizi selezionati sono esauriti"])}
    },
    "pago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il pagamento"])},
    "confirmacionPago": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo pagamento è in fase di verifica."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un problema di comunicazione con il gateway di pagamento. Questo problema dovrebbe risolversi automaticamente entro poche ore. Se la tua prenotazione non appare nel tuo profilo entro 24 ore, ti preghiamo di contattarci."])}
    },
    "pagoTransferencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pagamento per questa prenotazione avviene tramite bonifico bancario"])},
    "cargaInformacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un problema nel caricamento delle informazioni. Riprova più tardi"])},
    "controlErrorValidarCupo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il viaggio richiesto non esiste"])},
    "noAdmiteReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il viaggio non accetta una nuova prenotazione"])},
    "inesperado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore imprevisto. Riprova più tardi"])},
    "precioCambiado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stato rilevato un cambiamento di prezzo"])},
    "loginReservaDescartar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi effettuare il login per eliminare la prenotazione"])},
    "permisosDescartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i permessi per eliminare la prenotazione"])},
    "descartarReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare la prenotazione"])},
    "intentalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore imprevisto. Riprova più tardi"])},
    "obligatorios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono campi obbligatori non compilati"])},
    "loginReservaCrear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi effettuare il login per creare la prenotazione"])},
    "permisosCrearReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i permessi per creare la prenotazione"])},
    "noInfoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato possibile trovare le informazioni sulla prenotazione"])},
    "existeReservaViaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste già una prenotazione per questo viaggio"])},
    "emailRegistrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'indirizzo email è già registrato"])},
    "permisosProcesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i permessi per eseguire il processo"])},
    "camposSinCompletar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci sono campi non compilati"])},
    "recursoNoLocalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La risorsa richiesta non è stata trovata"])},
    "conflictoEstadoReserva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esiste un conflitto nello stato della prenotazione"])},
    "errorContrasenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nell'inserimento della password"])},
    "loginRequerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi effettuare il login per continuare"])},
    "reservaNoPendiente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prenotazione non è in stato pendente"])},
    "noAlojamientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun alloggio trovato"])},
    "intenteReservaTarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, prova a prenotare più tardi. Se l'errore persiste, contattaci."])},
    "generacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella generazione"])},
    "descarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nel download"])}
  },
  "servicios": {
    "repercutirFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni di gestione"])},
    "tasaDe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Commissioni di ", _interpolate(_named("nombreTasa"))])},
    "forfait": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skipass"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi cambiare i giorni del tuo skipass. Seleziona i giorni desiderati"])}
    },
    "comida": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibo"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri il pranzo sulle piste"])}
    },
    "transporte": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasporti"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tuo mezzo di trasporto"])}
    },
    "seguro": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazioni"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la tua assicurazione"])},
      "verPoliza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza polizza"])},
      "subtipo": {
        "cancelacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento"])},
        "salud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salute"])}
      }
    },
    "actividad": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli l'attività"])}
    },
    "restauracion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cibo"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri il pranzo sulle piste"])}
    },
    "festival": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festival"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il biglietto che preferisci e vieni a goderti l'esperienza completa"])}
    },
    "clase": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lezioni"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi scegliere i giorni delle lezioni. Seleziona i giorni desiderati"])}
    },
    "alquiler": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noleggi"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri noleggiare l'attrezzatura"])}
    },
    "greenFee": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green Fee"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri un Green Fee."])}
    },
    "relax": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli se desideri un servizio relax."])}
    },
    "personal": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personale"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il personale."])}
    },
    "parking": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il tuo parcheggio preferito"])}
    },
    "mascota": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animali Domestici"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli i servizi per gli animali domestici"])}
    },
    "competicion": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concorso"])},
      "subtitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli la competizione"])}
    },
    "tooltipMessageIsNominal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcuni servizi sono nominali, il che significa che è possibile selezionare un solo servizio per persona."])},
    "servicioNominal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio nominale"])},
    "noDisponibilidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono più disponibilità per questo servizio"])},
    "maximoPermitido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto il numero massimo di servizi per persona"])}
  },
  "trabajaNosotros": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavora con Noi"])}
  },
  "cardReservaPlazasLibres": {
    "descripcion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hai ancora ", _interpolate(_named("plazasLibres")), " posti disponibili nel tuo alloggio, che corrispondono a un pagamento in sospeso di ", _interpolate(_named("pagoPendiente")), ". Hai due opzioni:"])},
    "opcionUno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi il link per completare i posti disponibili."])},
    "opcionDos": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pagare l'importo pendente di ", _interpolate(_named("pagoPendiente")), " tra le persone confermate."])},
    "cancelacion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se nessuna delle due opzioni viene effettuata prima del ", _interpolate(_named("fecha")), ", la ", _interpolate(_named("final"))])},
    "cancelacionBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prenotazione verrà automaticamente cancellata."])},
    "msjPlazaLibresPagadas": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se hai dei posti liberi ", _interpolate(_named("plazasLibres")), ", condividi il link per completarli e il supplemento “Posti liberi” verrà ricalcolato a tuo favore."])},
    "button": {
      "abonarPago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga l'importo in sospeso"])},
      "copiarLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia link"])}
    }
  },
  "langs": {
    "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spagnolo"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francese"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
    "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalano"])}
  }
}