<template>
	<div v-if="agrupacion.servicios" class="col my-2 translate">
		<div class="title-card">
			<div class="text">
				<i :class="informacionServicio.icono" />
				<span class="translate">{{ agrupacion.nombre }}</span>
			</div>
			<div class="d-flex">
				<div v-if="servicioSeleccionado" class="d-flex">
					<span v-if="servicioSeleccionado.precio" class="text-secondary mx-2">
						<span v-if="servicioSeleccionado.precio != 0">
							<span v-if="servicioSeleccionado.precio > 0">+</span>
							{{ servicioSeleccionado.precio }}{{agenciaStore.getCurrency}}
						</span>
					</span>
					<span v-if="servicioSeleccionado.precio < 0" class="dot-disabled" />
					<span v-if="servicioSeleccionado.precio >= 0 && !servicioSeleccionado.nulo" class="dot" />
				</div>
			</div>
		</div>
		<div class="body-card px-3">
			<p class="mt-3">
				{{ informacionServicio.subtitulo }}
			</p>
			<p v-if="servicioSeleccionado && !servicioSeleccionado.nulo" class="text-muted fw-bold">
				{{ servicioSeleccionado.descripcion }}
			</p>
			<div class="row d-flex flex-column flex-md-row">
				<div class="col col-md-3">
					<select v-model="firstFilter" class="form-select" @change="reiniciarFiltroModalidad()">
						<option :value="null">
							{{ informacionServicioNulo.nombre }}
						</option>
						<option v-for="filter in agrupacion.filtros" :key="filter.id" :value="filter">
							{{ filter.nombre }}
						</option>
					</select>
				</div>
				<div v-if="firstFilter" class="col col-md-3 mt-2 mt-md-0">
					<select v-model="secondFilter" class="form-select" @change="resetSecondFilter()">
						<option :value="null" selected disabled>Seleccionar gama</option>
						<option v-for="filter in firstFilter.secondFilters" :key="filter.id" :value="filter">
							{{ filter.nombre }}
						</option>
					</select>
				</div>
				<div v-if="secondFilter" class="col col-md-6 mt-2 mt-md-0">
					<select v-model="servicioSeleccionado" class="form-select">
						<option :value="null" selected disabled>Seleccionar servicio</option>
						<option v-for="servicio in serviciosFiltrados.filter(s => s.activo)" :key="servicio.id" :value="servicio">
							{{ servicio.nombre }} ({{ formatDateDDmm(servicio.fechaInicio) }} {{ $t('general.al') }}
							{{ formatDateDDmm(servicio.fechaFin) }})
							<span v-if="servicio.precio > 0">(+</span>
							<span v-if="servicio.precio">
								<span v-if="servicio.precio < 0">(</span>
								{{ servicio.precio }}{{agenciaStore.getCurrency}})
							</span>
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, watch, computed } from 'vue';
	import { formatDateDDmm } from '@/helpers/dateUtiles';
	import { selectServiceText } from '@/constants/selectService';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	export default {
		props: {
			agrupacion: {
				type: Object,
				required: true,
			},
			servicioPredefinido: {
				type: Object,
				default: null,
			},
		},
		emits: ['servicioActualizado'],
		setup(props, { emit }) {
			const agenciaStore = agenciaStoreModule();

			const informacionServicios = { ...props.agrupacion.servicios };

			const informacionServicioNulo = { ...props.servicioPredefinido };

			const informacionServicio = selectServiceText[props.agrupacion.tipo];

			const firstFilter = ref(null);
			const secondFilter = ref(null);

			const serviciosFiltrados = ref();
			const servicioSeleccionado = ref(null);

			const filtrarListaServicios = () => {
				let serviciosFiltradosAux = [];
				Object.values(informacionServicios).map(servicio => {
					serviciosFiltradosAux.push(servicio);
				});
				serviciosFiltrados.value = serviciosFiltradosAux.filter(s =>
					s.firstFilter.id == firstFilter.value.id && s.secondFilter.id == secondFilter.value.id && !s.nulo
				);
			};

			const existeInformacion = computed(() => informacionServicios);

			if (existeInformacion.value) {
				watch([firstFilter, secondFilter], () => {
					if (!firstFilter.value) {
						servicioSeleccionado.value = informacionServicioNulo;
					}
					if (firstFilter.value && secondFilter.value) {
						filtrarListaServicios();
					}
				});

				servicioSeleccionado.value = informacionServicioNulo;
			}

			//CONTROL DE FILTROS
			const reiniciarFiltroModalidad = () => {
				secondFilter.value = null;
				servicioSeleccionado.value = null;
			};

			const resetSecondFilter = () => {
				servicioSeleccionado.value = null;
			};

			//AGREGAR INFORMACION AL STORE PARA MOSTRAR EN CARD RESUMEN

			watch(servicioSeleccionado, () => {
				let servicioActualizar = {
					[props.agrupacion.tipo]: servicioSeleccionado.value,
				};
				emit('servicioActualizado', servicioActualizar);
			});

			return {
				firstFilter,
				serviciosFiltrados,
				secondFilter,
				servicioSeleccionado,
				resetSecondFilter,
				reiniciarFiltroModalidad,
				informacionServicioNulo,
				formatDateDDmm,
				existeInformacion,
				informacionServicio,
				agenciaStore,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;
			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}
</style>
